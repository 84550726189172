import React from 'react';

import { createBem } from '@airnz/ui/bem';
import DocketTable from '../DocketTable';
import DocketData from '../../models/DocketData';
import MobileDocketList from '../MobileDocketList';

import './DocketList.scss';

interface DocketListProps {
  dockets: DocketData[];
}

const bem = createBem('fuel-DocketList');
// Show/hide the DocketTable and MobileDocketList with css rather than `useBreakpoints` to ensure they work with `window.print`
const DocketList = ({ dockets }: DocketListProps) => (
  <div className={bem()}>
    <DocketTable dockets={dockets} className={bem('desktop')} />
    <MobileDocketList dockets={dockets} className={bem('mobile')} />
  </div>
);

export default DocketList;
