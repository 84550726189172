import React from 'react';

import PageHeading from '@airnz/ui/PageHeading';
import PageBlock from '@airnz/ui/PageBlock';
import PageDescription from '@airnz/ui/PageDescription';
import AirNewZealand from '@airnz/ui/AirNewZealand';
import { createBem } from '@airnz/ui/bem';

const bem = createBem('fuel-NoAccess');

const NoAccess = () => (
  <PageBlock className={bem()}>
    <PageHeading>Sorry, you don’t have access</PageHeading>
    <PageDescription>
      You don’t have access to the fuel docket app.
    </PageDescription>
    <PageDescription>
      If you are a fueller for <AirNewZealand />, contact your Airport Manager
      to request access.
    </PageDescription>
  </PageBlock>
);

export default NoAccess;
