import React from 'react';

import { createBem, combineClassNames } from '@airnz/ui/bem';

import { Role } from '../../models/User';
import { SearchParameters } from '../../models/DocketSearchParams';
import SearchSummary from '../SearchSummary';
import SortField, { SortFieldValueType } from '../SortField';

import './SearchUtils.scss';

interface SearchUtilsProps {
  canSearch: boolean;
  canSort: boolean;
  sortValue: SortFieldValueType;
  onChangeSort(value: string): void;
  role: Role;
  searchParams: SearchParameters;
  className?: string;
}

const bem = createBem('fuel-SearchUtils');

const SearchUtils = ({
  canSearch,
  canSort,
  sortValue,
  onChangeSort,
  role,
  searchParams,
  className,
}: SearchUtilsProps) => (
  <div className={bem()}>
    {canSearch ? (
      <SearchSummary filters={searchParams} className={bem('searchSummary')} />
    ) : (
      <div />
    )}
    {canSort && (
      <SortField
        onChange={onChangeSort}
        value={sortValue}
        role={role}
        className={combineClassNames(bem('sortField'), className)}
      />
    )}
  </div>
);

export default SearchUtils;
