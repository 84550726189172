export default {
  privacy: {
    id: 'fuel.PageFooter.privacy',
    defaultMessage: 'Privacy',
  },
  terms: {
    id: 'fuel.PageFooter.terms',
    defaultMessage: 'Terms & conditions',
  },
};
