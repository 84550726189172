import React, { useState, useEffect } from 'react';

import Button from '@airnz/ui/Button';
import HeaderUserPopover from '@airnz/ui/HeaderUserPopover';
import HeaderUserPopoverGreeting from '@airnz/ui/HeaderUserPopoverGreeting';
import HeaderUserPopoverSection from '@airnz/ui/HeaderUserPopoverSection';
import Paragraph from '@airnz/ui/Paragraph';

import createGtmEvent from '../../utils/createGtmEvent';
import User, { Role } from '../../models/User';
import { getOrganisations, getPorts } from './ProfileMenu.util';

interface ProfileMenuProps {
  user: User;
  signOut(): void;
}

const ProfileMenu = ({ user, signOut }: ProfileMenuProps) => {
  const [open, setOpen] = useState(false);
  const firstName = user.name.split(' ')[0];

  let userTypeInfo: string | JSX.Element;
  switch (user.role) {
    case Role.fueller:
      userTypeInfo = (
        <span>
          {getOrganisations(user.organisations)} fueller at{' '}
          {getPorts(user.airportCodes)}
        </span>
      );
      break;
    case Role.AOC:
      userTypeInfo = <span>AOC at {getPorts(user.airportCodes)}</span>;
      break;
    case Role.engineer:
      userTypeInfo = <span>Engineer at {getPorts(user.airportCodes)}</span>;
      break;
    case Role.admin:
      userTypeInfo = 'Admin user';
      break;
    case Role.support:
      userTypeInfo = 'Support user';
      break;
    default:
      userTypeInfo = '';
  }

  const openPopover = () => {
    setOpen(true);
    createGtmEvent('Header', 'Profile Menu', 'Open');
  };

  const closePopover = () => {
    setOpen(false);
    createGtmEvent('Header', 'Profile Menu', 'Close');
  };

  // Cleanup when component unmounts to prevent memory leak
  useEffect(
    () => () => {
      setOpen(false);
    },
    []
  );

  return (
    <HeaderUserPopover
      open={open}
      userName={user.name}
      onOpen={openPopover}
      onClose={closePopover}
    >
      <HeaderUserPopoverSection>
        <HeaderUserPopoverGreeting name={firstName} />
        <Paragraph>{userTypeInfo}</Paragraph>
      </HeaderUserPopoverSection>
      <HeaderUserPopoverSection>
        <Button size="sm" onClick={signOut}>
          Sign out
        </Button>
      </HeaderUserPopoverSection>
    </HeaderUserPopover>
  );
};

export default ProfileMenu;
