import User, { Organisation, Role } from '../../models/User';
import { ExtendedAccountInfo, ExtendedIdTokenClaims } from '../../utils/msal';

export const supportGroup = 'f-docket-support';
export const adminGroup = 'f-docket-admin';
export const aocGroupPrefix = 'f-AOC_';
export const engineerGroupPrefix = 'f-docket-eng_';

export const hasGroup = (roles: string[], groupPrefix: string) => {
  const indexOfAoc = roles.findIndex(str => str.includes(groupPrefix));
  return indexOfAoc !== -1;
};

const getPortsFromGroups = (
  groups: string[],
  groupPrefix: string
): Array<string> =>
  groups
    .filter(group => group.startsWith(groupPrefix))
    .map(each => each.replace(groupPrefix, ''));

export const getRole = (tokenClaims?: ExtendedIdTokenClaims): Role => {
  if (!tokenClaims) {
    return Role.none;
  }

  if (tokenClaims.extension_fDocketFueller) {
    return Role.fueller;
  }

  if (!tokenClaims.roles || !tokenClaims.roles.length) {
    return Role.none;
  }

  if (tokenClaims.roles?.includes(supportGroup)) {
    return Role.support;
  }

  if (hasGroup(tokenClaims.roles, aocGroupPrefix)) {
    return Role.AOC;
  }

  if (hasGroup(tokenClaims.roles, engineerGroupPrefix)) {
    return Role.engineer;
  }

  if (tokenClaims.roles?.includes(adminGroup)) {
    return Role.admin;
  }

  return Role.none;
};

export const getOrganisations = (
  tokenClaims?: ExtendedIdTokenClaims
): Organisation | Organisation[] => {
  if (!tokenClaims) {
    return Organisation.unknown;
  }

  if (tokenClaims?.extension_fDocketFueller) {
    return tokenClaims.extension_fuelerOrganisations
      ? (tokenClaims.extension_fuelerOrganisations.split(',') as Organisation[])
      : Organisation.unknown;
  } else {
    return Organisation.AirNZ;
  }
};

export const getAirportCodes = (
  role?: Role,
  tokenClaims?: ExtendedIdTokenClaims
): string[] | undefined => {
  if (!role || !tokenClaims) {
    return undefined;
  }

  // Fuellers have an airport(s) attribute in extended token claims
  if (role === Role.fueller) {
    return tokenClaims.extension_fuelerOperatingAirports
      ? tokenClaims.extension_fuelerOperatingAirports?.split(',')
      : undefined;
  }

  // AOCs and engineers have 1 or more AD groups that includes their airportCode, in the roles attribute, e.g. 'f-AOC_ZQN' or 'f-docket-eng_ZQN'
  if (tokenClaims.roles) {
    if (role === Role.AOC) {
      return getPortsFromGroups(tokenClaims.roles, aocGroupPrefix);
    }
    if (role === Role.engineer) {
      return getPortsFromGroups(tokenClaims.roles, engineerGroupPrefix);
    }
  }

  return undefined;
};

const tokenClaimsToUserTransform = (accountInfo: ExtendedAccountInfo): User => {
  if (!accountInfo || !Object.keys(accountInfo)) {
    throw new Error('Account info is empty. No user details can be derived.');
  }

  const tokenClaims = accountInfo.idTokenClaims;
  const role = getRole(tokenClaims);
  const organisations = getOrganisations(tokenClaims);
  const airportCodes = getAirportCodes(role, tokenClaims);

  return {
    name: accountInfo.name || '',
    role,
    organisations,
    airportCodes,
    isAuthenticated: true,
  };
};

export default tokenClaimsToUserTransform;
