import React, { SetStateAction, Dispatch } from 'react';

import ReadOnlyField from '@airnz/ui/ReadOnlyField';
import { SelectOption } from '@airnz/ui/Select';
import Alert from '@airnz/ui/Alert';
import SelectFormField from '@airnz/ui/forms/SelectFormField';
import { createBem, combineClassNames } from '@airnz/ui/bem';

interface SelectDocketOptionProps {
  options: SelectOption[];
  onChange?: Dispatch<SetStateAction<string>>;
  name: string;
  label: React.ReactNode;
  alertText: string;
  placeholder: string;
  className?: string;
}

const bem = createBem('fuel-SelectDocketOption');

const SelectDocketOption = ({
  options,
  onChange,
  name,
  label,
  alertText,
  placeholder,
  className,
}: SelectDocketOptionProps) => {
  if (options.length > 1) {
    return (
      <SelectFormField
        name={name}
        label={label}
        placeholder={placeholder}
        required
        options={options}
        className={combineClassNames(bem('select'), className)}
        onChange={onChange}
      />
    );
  } else {
    return (
      <ReadOnlyField
        label={label}
        className={combineClassNames(bem('readonly'), className)}
      >
        {options.length === 1 ? (
          options[0].label
        ) : (
          <Alert type="danger" minimal className={bem('alert')}>
            {alertText}
          </Alert>
        )}
      </ReadOnlyField>
    );
  }
};

export default SelectDocketOption;
