import React from 'react';

import TextFormField from '@airnz/ui/forms/TextFormField';
import ReadOnlyField from '@airnz/ui/ReadOnlyField';

interface DocketFormTextFieldProps {
  name: string;
  label: React.ReactElement;
  placeholder?: string;
  hint?: React.ReactElement;
  validator?(value: string): undefined | string;
  readOnly: boolean;
  readOnlyValue?: string;
  className?: string;
  onChange?(value: string): void;
  normalize?(value: string): string;
}
const DocketFormTextField = ({
  name,
  label,
  placeholder,
  hint,
  validator,
  readOnly,
  readOnlyValue,
  className,
  onChange,
  normalize,
}: DocketFormTextFieldProps) =>
  readOnly ? (
    <ReadOnlyField label={label} className={className}>
      {readOnlyValue}
    </ReadOnlyField>
  ) : (
    <TextFormField
      name={name}
      label={label}
      placeholder={placeholder}
      hint={hint}
      validator={validator}
      required
      className={className}
      onChange={onChange}
      normalize={normalize}
      autoComplete="off"
    />
  );

export default DocketFormTextField;
