export enum Role {
  AOC = 'AOC',
  support = 'support',
  fueller = 'fueller',
  admin = 'admin',
  none = 'none',
  unknown = 'unknown',
  engineer = 'engineer',
}

export enum Organisation {
  BP = 'BP',
  Mobil = 'Mobil',
  Z = 'Z',
  Gull = 'Gull',
  AirNZ = 'AirNZ',
  Viva = 'Viva',
  Ampol = 'Ampol',
  Menzies = 'Menzies',
  Shell = 'Shell',
  BurntPine = 'Burnt Pine',
  Pacific = 'Pacific',
  Evergreen = 'Evergreen',
  unknown = '',
}

export type FuelProvider = Omit<Organisation, 'AirNZ'>;

export interface User {
  name: string;
  organisations: Organisation | Organisation[];
  role: Role;
  airportCodes?: string[];
  isAuthenticated: boolean;
}

export default User;
