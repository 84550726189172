import React, { useEffect, useState } from 'react';

import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccessToken } from '../../utils/msal';
import { initialUserState } from '../../state/initialState';
import User from '../../models/User';
import UserContext from '../../state/UserContext';

interface UserContextProviderProps {
  children: React.ReactNode;
}

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [user, setUser] = useState<User>(initialUserState);
  const { acquireAccessToken } = useAccessToken() || {};
  const [hasAuthenticated, setHasAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (!hasAuthenticated && account && inProgress === InteractionStatus.None) {
      acquireAccessToken &&
        acquireAccessToken().then(response => {
          if (
            response instanceof Object &&
            response.status === 'success' &&
            response.user
          ) {
            setUser({ ...response.user, isAuthenticated: true });
            setHasAuthenticated(true);
          }
        });
    }
  }, [account, acquireAccessToken, inProgress, hasAuthenticated]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
