import DocketData from '../models/DocketData';
import User, { Organisation, Role } from '../models/User';
import { SearchParameters } from '../models/DocketSearchParams';
import { getMonthAgoDateString, getTodayDateString } from '../utils/formatDate';
import ModalData from '../models/ModalData';

export const initialDocketFormState: DocketData = {
  docketNumber: '',
  flightNumber: 'NZ',
  totalFuelRequested: '',
  totalFuelRequestedUnitOfMeasure: 'KILOGRAMS',
  preTransactionFuel: '',
  preTransactionFuelUnitOfMeasure: 'KILOGRAMS',
  aircraftRegistration: 'ZK',
  volumeTransferred: '',
  volumeTransferredUnitOfMeasure: 'LITERS',
  density: '',
  densityUnitOfMeasure: 'KILOGRAMS PER LITER',
  fuelDocketType: 'FUEL',
  fuelProvider: Organisation.unknown,
  createdBy: '',
  createdDateTimeLocal: '',
  airportCode: '',
  fuelDocketId: '',
};

export const initialUserState: User = {
  name: '',
  organisations: Organisation.unknown,
  airportCodes: [],
  role: Role.unknown,
  isAuthenticated: false,
};

export const initialPageState = 1;

export const pageSize = 20;

export const lastMonthRange = {
  from: getMonthAgoDateString(),
  to: getTodayDateString(),
};

export const initialDocketSearchState: SearchParameters = {
  flightNumber: '',
  aircraftRegistration: '',
  docketNumber: '',
  dateRange: lastMonthRange,
  airportCode: '',
  pageSize,
  page: initialPageState,
  createdBy: '',
  fuelProvider: '',
};

export const initialDocketSortState = 'dateDesc';

export const initialModalState: ModalData = {
  heading: null,
  body: null,
  actions: null,
};
