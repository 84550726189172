import React from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import { createBem, combineClassNames } from '@airnz/ui/bem';
import Label from '@airnz/ui/Label';
import ReadOnlyField from '@airnz/ui/ReadOnlyField';
import { H2 } from '@airnz/ui/Heading';
import ChevronRight from '@airnz/ui/icons/ChevronRight';
import { IntlTimeLocal } from '@airnz/ui/intl';

import { createDocketPath } from '../../utils/routeConstants';
import DocketData from '../../models/DocketData';
import getUnit from '../../utils/getUnit';
import createGtmEvent from '../../utils/createGtmEvent';
import { formatWithCommas } from '../../utils/formatNumbers';

import './MobileDocketList.scss';

const bem = createBem('fuel-MobileDocketList');

interface MobileDocketListProps {
  dockets: DocketData[];
  className?: string;
}

const MobileDocketList = ({ dockets, className }: MobileDocketListProps) => (
  <div className={combineClassNames(bem(), className)}>
    {Boolean(dockets.length) &&
      dockets.map((docket: DocketData) => (
        <Link
          to={{
            pathname: createDocketPath(docket.fuelDocketId),
          }}
          className={bem('link', docket.docketNumber)}
          key={`row-${docket.fuelDocketId}`}
          onClick={() =>
            createGtmEvent(
              'Dashboard',
              'Click on single docket (mobile)',
              docket.fuelDocketId
            )
          }
        >
          <div className={bem('docket')}>
            <H2>{`Docket ${docket.docketNumber}`}</H2>
            <div className={bem('docketData')}>
              <div className={bem('item')}>
                <Label>Created at</Label>
                <ReadOnlyField>
                  <IntlTimeLocal
                    value={docket.createdDateTimeLocal}
                    clockMode="24h"
                  />
                  <div>{format(docket.createdDateTimeLocal, 'DD/MM/YY')}</div>
                </ReadOnlyField>
              </div>
              <div className={bem('item')}>
                <Label>Created by</Label>
                <ReadOnlyField>{docket.createdBy}</ReadOnlyField>
              </div>
              <div className={bem('item')}>
                <Label>Fuel provider</Label>
                <ReadOnlyField>{docket.fuelProvider}</ReadOnlyField>
              </div>
              <div className={bem('item')}>
                <Label>Airport</Label>
                <ReadOnlyField>{docket.airportCode}</ReadOnlyField>
              </div>
              <div className={bem('item')}>
                <Label>Aircraft registration</Label>
                <ReadOnlyField>{docket.aircraftRegistration}</ReadOnlyField>
              </div>
              <div className={bem('item')}>
                <Label>Flight number</Label>
                <ReadOnlyField>{docket.flightNumber || '–'}</ReadOnlyField>
              </div>
              <div className={bem('item')}>
                <Label>Volume delivered</Label>
                <ReadOnlyField>
                  {`${formatWithCommas(docket.volumeTransferred)} ${getUnit(
                    docket.volumeTransferredUnitOfMeasure
                  )}`}
                </ReadOnlyField>
              </div>
            </div>
            <ChevronRight className={combineClassNames(bem('chevron'))} />
          </div>
        </Link>
      ))}
  </div>
);

export default MobileDocketList;
