import './google-analytics';
import React from 'react';
import { Router } from 'react-router-dom';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import AppRoot from '@airnz/ui/AppRoot';
import PageBody from '@airnz/ui/PageBody';
import { createBem } from '@airnz/ui/bem';
import LoadingOverlay from '@airnz/ui/LoadingOverlay';
import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';
import history from './utils/history';
import { loginRequest } from './utils/msal';
import AuthenticationError from './components/AuthenticationError';
import UserContextProvider from './components/UserContextProvider';
import UiConfigContextProvider from './components/UiConfigContextProvider';
import { ConnectedApiProvider } from './components/ApiProvider';
import Routes from './routes/Routes';
import createGtmEvent from './utils/createGtmEvent';

import '@airnz/ui/fonts';

import './App.scss';

interface AppProps {
  msalInstance: PublicClientApplication;
}

const locale = 'en-NZ';

const bem = createBem('fuel-App');

const LoadingComponent = () => <LoadingOverlay open />;

export const App = ({ msalInstance }: AppProps) => {
  const signOut = () => {
    if (window.dataLayer) {
      createGtmEvent('Header', 'Click link', 'Sign out');
    }
    msalInstance.logoutRedirect();
  };

  return (
    <MsalProvider instance={msalInstance}>
      <Router history={history}>
        <AppRoot locale={locale} messages={{}} className={bem()}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            errorComponent={AuthenticationError}
            loadingComponent={LoadingComponent}
          >
            <UserContextProvider>
              <ConnectedApiProvider>
                <UiConfigContextProvider>
                  <PageHeader signOut={signOut} />
                  <PageBody className={bem('content')}>
                    <Routes />
                  </PageBody>
                </UiConfigContextProvider>
              </ConnectedApiProvider>
            </UserContextProvider>
            <PageFooter className={bem('footer')} />
          </MsalAuthenticationTemplate>
        </AppRoot>
      </Router>
    </MsalProvider>
  );
};

export default App;
