import isAfter from 'date-fns/is_after';

import DocketData from '../models/DocketData';
import { SortFieldValueType } from '../components/SortField';

const compareStrings = (a: string, b: string): number => a.localeCompare(b);

const sortDockets = (
  dockets: DocketData[],
  sortValue: SortFieldValueType
): DocketData[] =>
  dockets.sort((a: DocketData, b: DocketData) => {
    switch (sortValue) {
      case 'docketNumber':
        return compareStrings(a.docketNumber, b.docketNumber);
      case 'createdBy':
        return a.createdBy.localeCompare(b.createdBy);
      case 'airportCode':
        return a.airportCode.localeCompare(b.airportCode);
      case 'dateAsc':
        return isAfter(a.createdDateTimeLocal, b.createdDateTimeLocal) ? 1 : -1;
      case 'dateDesc':
        return isAfter(b.createdDateTimeLocal, a.createdDateTimeLocal) ? 1 : -1;
      default:
        return compareStrings(a.docketNumber, b.docketNumber);
    }
  });

export default sortDockets;
