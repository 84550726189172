import React, { useState } from 'react';

import { createBem } from '@airnz/ui/bem';
import SubmitButton from '@airnz/ui/forms/SubmitButton';
import Search from '@airnz/ui/icons/Search';
import Form from '@airnz/ui/forms/Form';
import ShowHideTrigger from '@airnz/ui/ShowHideTrigger';
import useToggle from '@airnz/ui/useToggle';
import Expandable from '@airnz/ui/Expandable';
import TextFormField from '@airnz/ui/forms/TextFormField';
import PageActions from '@airnz/ui/PageActions';
import SelectFormField from '@airnz/ui/forms/SelectFormField';
import IconText from '@airnz/ui/IconText';

import AutocompleteInput from '../AutoCompleteInput';
import SearchDatePicker from './SearchDatePicker';
import readPortConfigOptions from '../../utils/config/portList';
import readFuelProviders from '../../utils/config/fuelProviderList';
import {
  AirportCode,
  FormValues,
  SearchParameters,
} from '../../models/DocketSearchParams';
import { initialDocketSearchState } from '../../state/initialState';
import validators from '../../utils/validators';
import User from '../../models/User';
import createGtmEvent from '../../utils/createGtmEvent';
import formatSearchParamsForAnalytics from '../../utils/formatSearchParamsForAnalytics';
import { UIConfiguration } from '../../models/UIConfigData';
import './DocketSearch.scss';

const bem = createBem('fuel-DocketSearch');

export interface DocketSearchProps {
  filters: SearchParameters;
  changeFilters(filters: SearchParameters): void;
  user?: User;
  uiConfig: UIConfiguration;
}

const DocketSearch = ({
  changeFilters,
  filters,
  user,
  uiConfig,
}: DocketSearchProps) => {
  const [page] = useState(initialDocketSearchState.page);
  const { pageSize } = initialDocketSearchState;
  const [showSearch, setShowSearch] = useToggle(false);

  // The port autocomplete field does not use airnzui Form's internal state
  const [airportCode, setAirportCode] = useState<AirportCode>(
    initialDocketSearchState.airportCode || ''
  );

  const initialFormValues: FormValues = {
    flightNumber: initialDocketSearchState.flightNumber,
    aircraftRegistration: initialDocketSearchState.aircraftRegistration,
    docketNumber: initialDocketSearchState.docketNumber,
    createdBy: initialDocketSearchState.createdBy,
    fuelProvider: initialDocketSearchState.fuelProvider,
    dateRange: initialDocketSearchState.dateRange,
  };

  const handleFormSubmit = (values: FormValues) => {
    const allActiveValues: SearchParameters = {
      ...values,
      airportCode,
      pageSize,
      page,
    };
    changeFilters(allActiveValues);
    createGtmEvent(
      'Dashboard',
      'Search',
      formatSearchParamsForAnalytics(allActiveValues)
    );
  };

  const searchIcon = <Search size={16} />;

  return (
    <div className={bem()}>
      <Form<SearchParameters>
        onSubmit={handleFormSubmit}
        initialValues={{ ...initialFormValues, ...filters }}
      >
        <div className={bem('form')}>
          <TextFormField
            label="Docket number"
            name="docketNumber"
            autoComplete="off"
            className={bem('docketNumber')}
            validator={validators.isValidDocketNumberSearch}
          />
          <ShowHideTrigger
            showLabel={<IconText icon={searchIcon}>Advanced search</IconText>}
            hideLabel={
              <IconText icon={searchIcon}>Hide advanced search</IconText>
            }
            onClick={setShowSearch}
            isShowing={showSearch}
            className={bem('advancedSearchButton')}
          />
          <Expandable open={showSearch}>
            <div className={bem('expandable')}>
              <AutocompleteInput
                options={[
                  {
                    value: '',
                    label: 'All airports',
                  },
                  ...readPortConfigOptions(uiConfig.portConfigurations),
                ]}
                label="Port"
                onChange={setAirportCode}
                validator={validators.isValidAirportCode}
                uiConfig={uiConfig}
              />
              <SearchDatePicker />
              <TextFormField
                label="Aircraft registration"
                name="aircraftRegistration"
                autoComplete="off"
                normalize={(value: string) => value.replace('-', '')}
                validator={validators.isValidTailNumber}
                placeholder="e.g. ZKABC"
              />
              {user && user.role === 'support' ? (
                <>
                  <TextFormField
                    label="Flight number"
                    name="flightNumber"
                    autoComplete="off"
                    validator={validators.isValidFlightNumber}
                    placeholder="e.g. NZ123"
                    normalize={(value: string) =>
                      value.toUpperCase() === 'NZ' ? '' : value
                    }
                  />
                  <TextFormField
                    label="Created by"
                    name="createdBy"
                    autoComplete="off"
                    validator={validators.isValidCreatedBy}
                  />
                  <SelectFormField
                    name="fuelProvider"
                    label="Fuel provider"
                    placeholder="All fuel providers"
                    options={readFuelProviders(uiConfig.fuelProviders)}
                    className={bem('fuelProvider')}
                  />
                </>
              ) : null}
            </div>
          </Expandable>
          <PageActions className={bem('pageActions')}>
            <SubmitButton className={bem('searchButton')}>Search</SubmitButton>
          </PageActions>
        </div>
      </Form>
    </div>
  );
};

export default DocketSearch;
