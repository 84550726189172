import { MsalAuthenticationResult } from '@azure/msal-react';

export function buildAuthErrorMessage({
  error,
}: MsalAuthenticationResult): string {
  return [
    'An authentication error has occurred. Please refresh and try again, or contact the administrator if the problem persists.',
    error && `(Code: ${error.errorCode})`,
  ]
    .filter(Boolean)
    .join(' ');
}
