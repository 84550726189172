import React from 'react';

import Alert from '@airnz/ui/Alert';
import { createBem } from '@airnz/ui/bem';
import uuid from '@airnz/ui/uuid';
import StyledText from '@airnz/ui/StyledText';

import User, { Role } from '../../models/User';
import DocketData from '../../models/DocketData';

const bem = createBem('DocketError');

export interface ErrorMessageCode {
  messageCode: string;
  messageDetail: string;
  messageField: keyof DocketData;
  messageTemplate: any;
}

export interface ErrorResponseData {
  code: number;
  message: string;
  messageCodes: ErrorMessageCode[];
}

interface DocketEditAndCreateErrorMessageProps {
  docketNumber: string;
  errorDetails?: ErrorResponseData;
  mode: 'edit' | 'create';
  user: User;
}

const commonMessageCopy = {
  accountProblem:
    'There is likely a problem with your account. Please contact your account administrator.',
  docketDataProblem:
    'There is likely a problem with the existing docket data. Please contact support.',
};

const DocketsEditAndCreateErrorMessage = ({
  docketNumber,
  errorDetails,
  mode,
  user,
}: DocketEditAndCreateErrorMessageProps) => {
  const transformApiErrorMessage = (messageCode: ErrorMessageCode) => {
    let userAction: string;

    switch (messageCode.messageField) {
      case 'airportCode':
        if (user.role === Role.support) {
          userAction =
            'Please select an airport code from the dropdown above, and try again.';
        } else {
          userAction =
            mode === 'edit'
              ? commonMessageCopy.docketDataProblem
              : commonMessageCopy.accountProblem;
        }
        return `Docket submission failed because the airport code was missing or unsupported. ${userAction}`;
      case 'createdBy':
        return `The docket lacks a user. ${commonMessageCopy.accountProblem}`;
      case 'fuelProvider':
        if (mode === 'edit') {
          userAction = commonMessageCopy.docketDataProblem;
        } else {
          userAction =
            user.role === Role.support
              ? 'Please select a fuel provider from the dropdown above, and try again.'
              : commonMessageCopy.accountProblem;
        }
        return `Docket submission failed because the fuel provider was missing or unsupported. ${userAction}`;
      case 'docketNumber': {
        return docketNumber
          ? `Docket submission failed because the docket number ${docketNumber} is not a valid docket number format. Please try again.`
          : 'Docket submission failed because the docket number was missing. Please try again.';
      }
      case 'aircraftRegistration': {
        return 'Docket submission failed because the aircraft registration was invalid or missing. Please try again.';
      }
      case 'flightNumber': {
        return 'Docket submission failed because the flight number was invalid or missing. Please try again.';
      }
      default:
        return defaultMessage;
    }
  };

  const getErrorMessageFrom422 = (
    errorMessageCodes?: ErrorMessageCode[]
  ): string[] => {
    if (errorMessageCodes && errorMessageCodes.length) {
      return errorMessageCodes.map(code => transformApiErrorMessage(code));
    }

    return [
      'Docket submission failed because the docket data provided was invalid.',
    ];
  };

  const defaultMessage = `There was a problem ${
    mode === 'create' ? 'creating' : 'editing'
  } docket ${docketNumber}. Please try again, or contact the Airport Operations Controllers at your airport.`;

  let result;

  switch (errorDetails && errorDetails.code) {
    case 400: // Bad request
      result = defaultMessage;
      break;
    case 403:
      result = `You don’t have permission to ${
        mode === 'create' ? 'create' : 'edit'
      } fuel dockets.`;
      break;
    case 422:
      result = getErrorMessageFrom422(
        errorDetails && errorDetails.messageCodes
      );
      break;
    case 500:
      result = `There was a problem connecting to the fuel app server, and docket ${docketNumber} couldn’t be created. Please try again, or contact the Airport Operations Controllers at your airport.`;
      break;
    case 503:
      result = `Docket ${docketNumber} was submitted, but the message failed to reach the aircraft.
        Please contact the Airport Operations Controllers at your airport.`;
      break;
    default:
      result = defaultMessage;
  }

  // Ensure result is an array of unique values
  result = Array.isArray(result) ? [...new Set(result)] : [result];

  return (
    <Alert type="danger" minimal={result.length === 1} className={bem()}>
      <StyledText>
        {result.length > 1 ? (
          <ul>
            {result.map(message => (
              <li key={uuid()}>{message}</li>
            ))}
          </ul>
        ) : (
          result[0]
        )}
      </StyledText>
    </Alert>
  );
};

export default DocketsEditAndCreateErrorMessage;
