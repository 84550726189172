import React, { useEffect, useReducer, useCallback, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import Form from '@airnz/ui/forms/Form';
import PageActions from '@airnz/ui/PageActions';
import SubmitButton from '@airnz/ui/forms/SubmitButton';
import ReadOnlyField from '@airnz/ui/ReadOnlyField';
import { Message } from '@airnz/ui/intl';
import Alert from '@airnz/ui/Alert';
import LoadingSpinner from '@airnz/ui/LoadingSpinner';
import { createBem } from '@airnz/ui/bem';
import Expandable from '@airnz/ui/Expandable';
import { H3 } from '@airnz/ui/Heading';
import PageSection from '@airnz/ui/PageSection';
import { SelectOption } from '@airnz/ui/Select';

import DocketData from '../../models/DocketData';
import User, { Role, Organisation } from '../../models/User';
import validators from '../../utils/validators';
import DocketFormTextField from '../DocketFormTextField';
import getUnit from '../../utils/getUnit';
import readPortConfigOptions from '../../utils/config/portList';
import {
  defaultDensity,
  ATR_72_600,
  DASH_8_300,
} from '../../utils/formConstants';

import {
  DensityUnitOfMeasure,
  VolumeUnitOfMeasure,
  MassUnitOfMeasure,
} from '../../models/UnitOfMeasure';
import aircraftRegistrationReducer, {
  initialState as aircraftDetailsInitialState,
  actionCreators as aircraftActionCreators,
} from './aircraftRegistrationReducer';
import setValidationValue from '../../utils/setValidationValue';
import { isUSPortSelected } from '../../utils/isUSPortSelected';
import SelectedAircraftType from '../SelectedAircraftType';
import readFuelProviders from '../../utils/config/fuelProviderList';
import { createDocketPath } from '../../utils/routeConstants';
import LinkButton from '../LinkButton';

import messages from './DocketForm.messages';
import createGtmEvent from '../../utils/createGtmEvent';
import { UIConfiguration } from '../../models/UIConfigData';
import { GetAircraftDetailsConfigResponse } from '../../utils/api/fuelDocketApiClient';
import SelectDocketOption from '../SelectDocketOption';

export type SubmitHandlerType = (formData: DocketData) => Promise<any>;

export interface DocketFormProps {
  submitHandler: SubmitHandlerType;
  initialDocketState: DocketData;
  getAircraftDetailsConfig(): Promise<GetAircraftDetailsConfigResponse>;
  uiConfig: UIConfiguration;
  user: User;
  mode?: 'create' | 'restrictedEdit' | 'fullEdit';
}

let massUnitOfMeasure: MassUnitOfMeasure = 'KILOGRAMS';
let volumeUnitOfMeasure: VolumeUnitOfMeasure = 'LITERS';
let densityUnitOfMeasure: DensityUnitOfMeasure = 'KILOGRAMS PER LITER';

const turboprops = [ATR_72_600, DASH_8_300];

const bem = createBem('fuel-DocketForm');

const DocketForm = ({
  submitHandler,
  initialDocketState,
  getAircraftDetailsConfig,
  uiConfig,
  user,
  mode = 'create',
}: DocketFormProps) => {
  const [aircraftDetails, aircraftDetailsDispatch] = useReducer(
    aircraftRegistrationReducer,
    aircraftDetailsInitialState
  );
  const { inProgress } = useMsal();

  const {
    aircraftDetailsList,
    aircraftDetailsFetchError,
    aircraftValidator,
    selectedAircraft,
    maxMass,
    maxVolume,
    densityRange,
  } = aircraftDetails;

  const userHasLimitedPorts = (role: Role) =>
    role === Role.engineer || role === Role.fueller;

  const aircraftName =
    aircraftDetailsList.find(
      item =>
        item.aircraftRegistration === selectedAircraft?.aircraftRegistration
    )?.aircraftName || '';
  const isTurboprop = turboprops.includes(aircraftName);

  const aircraftRegistrationChangeHandler = useCallback(
    (value: string) => {
      if (aircraftValidator && !aircraftValidator(value)) {
        const aircraftMatch = aircraftDetailsList.filter(
          aircraft =>
            aircraft.aircraftRegistration.toLowerCase() === value.toLowerCase()
        );

        if (!aircraftMatch.length) {
          throw new Error('No aircraft match this registration.');
        }

        if (aircraftMatch.length > 1) {
          throw new Error('Multiple aircraft match this registration.');
        }
        aircraftDetailsDispatch(
          aircraftActionCreators.setSelectedAircraft(aircraftMatch[0])
        );
      } else {
        aircraftDetailsDispatch(
          aircraftActionCreators.setSelectedAircraft(undefined)
        );
      }
    },
    [aircraftValidator, aircraftDetailsList, aircraftDetailsDispatch]
  );

  useEffect(() => {
    if (inProgress === InteractionStatus.Logout) {
      return;
    }
    // Allow call stack/microtasks to clear, to prevent race condition with authentication
    // then load list of valid aircraft (a static list held by the fuel api)
    process.nextTick(() =>
      getAircraftDetailsConfig()
        .then(result =>
          aircraftDetailsDispatch(
            aircraftActionCreators.setAircraftDetailsList(result)
          )
        )
        .catch(error =>
          aircraftDetailsDispatch(
            aircraftActionCreators.setAircraftDetailsFetchError(error)
          )
        )
    );
  }, [getAircraftDetailsConfig, inProgress]);

  const [selectedPort, setSelectedPort] = useState('');

  useEffect(() => {
    // load validator to compare user input with the list of valid aircraft
    const port = initialDocketState.airportCode || selectedPort;
    if (aircraftDetailsList.length && !aircraftValidator && port) {
      aircraftDetailsDispatch(
        aircraftActionCreators.setAircraftValidator(
          aircraftDetailsList,
          port,
          uiConfig.portConfigurations
        )
      );
    }

    // Choose the existing aircraft registration value if present (i.e. edit mode)
    if (aircraftValidator && initialDocketState.aircraftRegistration) {
      aircraftRegistrationChangeHandler(
        initialDocketState.aircraftRegistration
      );
    }
  }, [
    aircraftDetailsList,
    aircraftValidator,
    initialDocketState.aircraftRegistration,
    aircraftRegistrationChangeHandler,
    initialDocketState.airportCode,
    selectedPort,
    uiConfig.portConfigurations,
  ]);

  const setUom = (
    massUom: MassUnitOfMeasure,
    volumeUom: VolumeUnitOfMeasure,
    densityUom: DensityUnitOfMeasure
  ) => {
    massUnitOfMeasure = massUom;
    volumeUnitOfMeasure = volumeUom;
    densityUnitOfMeasure = densityUom;
  };

  useEffect(() => {
    const port = initialDocketState.airportCode || selectedPort;
    if (port) {
      if (isUSPortSelected(uiConfig.portConfigurations, port)) {
        setUom('TONS', 'GALLONS', 'KILOGRAMS PER GALLON');
      } else {
        setUom('KILOGRAMS', 'LITERS', 'KILOGRAMS PER LITER');
      }
    }
  }, [
    selectedPort,
    initialDocketState.airportCode,
    uiConfig.portConfigurations,
  ]);

  useEffect(() => {
    if (selectedAircraft) {
      aircraftDetailsDispatch(
        setValidationValue('mass', massUnitOfMeasure, selectedAircraft)
      );
      aircraftDetailsDispatch(
        setValidationValue('density', densityUnitOfMeasure, selectedAircraft)
      );
      aircraftDetailsDispatch(
        setValidationValue('volume', volumeUnitOfMeasure, selectedAircraft)
      );
    }
  }, [selectedAircraft]);

  const getPortOptions = (): SelectOption[] => {
    if (mode === 'restrictedEdit') {
      return [
        {
          value: initialDocketState.airportCode,
          label: initialDocketState.airportCode,
        },
      ];
    }
    if (user.role === Role.support) {
      return readPortConfigOptions(uiConfig.portConfigurations);
    }
    if (userHasLimitedPorts(user.role) && user.airportCodes?.length) {
      return user.airportCodes.map(option => ({
        value: option,
        label: option,
      }));
    }
    return [];
  };

  const getFuelProviderOptions = (): SelectOption[] => {
    if (
      user.role === Role.engineer ||
      (user.role === Role.support && mode === 'create')
    ) {
      return readFuelProviders(uiConfig.fuelProviders);
    }
    if (userHasLimitedPorts(user.role)) {
      if (
        user.organisations === Organisation.unknown ||
        !user.organisations ||
        user.organisations === []
      ) {
        return [];
      }
      return typeof user.organisations === 'object' && user.organisations.length
        ? user.organisations.map(org => ({
            value: org,
            label: org,
          }))
        : [];
    }
    return [
      {
        value: initialDocketState.fuelProvider as string,
        label: initialDocketState.fuelProvider as string,
      },
    ];
  };

  return (
    <div className={bem()}>
      {Boolean(!aircraftDetailsFetchError && !aircraftDetailsList.length) && (
        <LoadingSpinner />
      )}
      {Boolean(aircraftDetailsFetchError) && (
        <Alert type="danger" minimal>
          There was a problem loading aircraft information. Please refresh the
          page, or contact the Airport Operations Controllers at your airport.
        </Alert>
      )}
      {Boolean(aircraftDetailsList.length && !aircraftDetailsFetchError) && (
        <Form
          onSubmit={(formData: DocketData) => {
            formData.preTransactionFuelUnitOfMeasure = massUnitOfMeasure;
            formData.totalFuelRequestedUnitOfMeasure = massUnitOfMeasure;
            formData.volumeTransferredUnitOfMeasure = volumeUnitOfMeasure;
            formData.densityUnitOfMeasure = densityUnitOfMeasure;
            formData.density = isTurboprop ? defaultDensity : formData.density;
            return submitHandler(formData);
          }}
          initialValues={initialDocketState}
        >
          <PageSection>
            <ReadOnlyField
              label={<Message {...messages.fuellerLabel} />}
              className={bem('createdBy')}
            >
              {initialDocketState.createdBy}
            </ReadOnlyField>
            {user.organisations !== Organisation.unknown && (
              <SelectDocketOption
                className={bem('fuelProvider')}
                options={getFuelProviderOptions()}
                name="fuelProvider"
                label="Fuel provider"
                placeholder="Fuel provider"
                alertText="Your account does not have an assigned fuel provider."
              />
            )}
            <SelectDocketOption
              className={bem('port')}
              options={getPortOptions()}
              onChange={setSelectedPort}
              name="airportCode"
              label="Port"
              placeholder="Airport code"
              alertText="Your account does not have an assigned airport."
            />
            <Expandable open={!selectedAircraft && mode === 'create'}>
              <H3>First, enter the aircraft’s registration</H3>
            </Expandable>
            <div className={bem('aircraftRegistrationContainer')}>
              <DocketFormTextField
                name="aircraftRegistration"
                readOnly={false}
                readOnlyValue={initialDocketState.aircraftRegistration}
                label={<Message {...messages.aircraftRegistrationLabel} />}
                className={bem('aircraftRegistration')}
                validator={aircraftValidator}
                onChange={aircraftRegistrationChangeHandler}
                normalize={(value: string) =>
                  value.replace('-', '').toUpperCase()
                }
              />
            </div>
          </PageSection>
          <Expandable
            open={Boolean(selectedAircraft) || mode !== 'create'}
            className={'expandable'}
          >
            <PageSection>
              {selectedAircraft ? (
                <SelectedAircraftType selectedAircraft={selectedAircraft} />
              ) : null}
              <DocketFormTextField
                name="docketNumber"
                label={<Message {...messages.docketNumberLabel} />}
                readOnly={mode === 'restrictedEdit'}
                readOnlyValue={initialDocketState.docketNumber}
                className={bem('docketNumber')}
                validator={validators.isValidDocketNumber}
              />
              <DocketFormTextField
                name="flightNumber"
                label={<Message {...messages.flightNumberLabel} />}
                placeholder="e.g. NZ999"
                validator={validators.isValidFlightNumber}
                className={bem('flightNumber')}
                readOnly={false}
                readOnlyValue={initialDocketState.flightNumber}
              />
              <DocketFormTextField
                name="totalFuelRequested"
                label={<Message {...messages.fuelRequestedLabel} />}
                hint={
                  <Message {...messages.fuelRequestedHint(massUnitOfMeasure)} />
                }
                readOnly={mode === 'restrictedEdit'}
                readOnlyValue={`${
                  initialDocketState.totalFuelRequested
                } ${getUnit(
                  initialDocketState.totalFuelRequestedUnitOfMeasure
                )}`}
                validator={
                  maxMass && validators.createMaxFuelRequestedValidator(maxMass)
                }
                className={bem('totalFuelRequested')}
                normalize={(value: string) => value.replace(',', '')}
              />
              <DocketFormTextField
                name="preTransactionFuel"
                label={<Message {...messages.preTransactionFuelLabel} />}
                hint={
                  <Message
                    {...messages.preTransactionFuelHint(massUnitOfMeasure)}
                  />
                }
                readOnly={mode === 'restrictedEdit'}
                readOnlyValue={`${
                  initialDocketState.preTransactionFuel
                } ${getUnit(
                  initialDocketState.preTransactionFuelUnitOfMeasure
                )}`}
                validator={
                  maxMass &&
                  validators.createMaxPreTransactionFuelValidator(maxMass)
                }
                className={bem('maxPreTransactionFuel')}
                normalize={(value: string) => value.replace(',', '')}
              />
              <DocketFormTextField
                name="volumeTransferred"
                label={<Message {...messages.volumeTransferredLabel} />}
                hint={
                  <Message
                    {...messages.volumeTransferredHint(volumeUnitOfMeasure)}
                  />
                }
                validator={
                  maxVolume &&
                  validators.createMaxVolumeTransferredValidator(maxVolume)
                }
                readOnly={mode === 'restrictedEdit'}
                readOnlyValue={`${
                  initialDocketState.volumeTransferred
                } ${getUnit(
                  initialDocketState.volumeTransferredUnitOfMeasure
                )}`}
                className={bem('volumeTransferred')}
                normalize={(value: string) => value.replace(',', '')}
              />
              <DocketFormTextField
                name="density"
                label={<Message {...messages.densityLabel} />}
                hint={<Message {...messages.densityHint(densityRange)} />}
                validator={
                  densityRange &&
                  validators.createDensityRangeValidator(densityRange)
                }
                readOnly={mode === 'restrictedEdit' || isTurboprop}
                readOnlyValue={`${
                  isTurboprop ? defaultDensity : initialDocketState.density
                } ${getUnit(initialDocketState.densityUnitOfMeasure)}`}
                className={bem('density')}
              />
              <PageActions>
                {initialDocketState.docketNumber ? (
                  <>
                    <SubmitButton className={bem('editButton')}>
                      <Message {...messages.resubmitButton} />
                    </SubmitButton>
                    <LinkButton
                      className={bem('cancelButton')}
                      to={createDocketPath(initialDocketState.fuelDocketId)}
                      linkData={initialDocketState}
                      onClick={() =>
                        createGtmEvent('Edit docket', 'Click cancel', 'Cancel')
                      }
                    >
                      <Message {...messages.cancelButton} />
                    </LinkButton>
                  </>
                ) : (
                  <SubmitButton className={bem('createButton')}>
                    <Message {...messages.submitButton} />
                  </SubmitButton>
                )}
              </PageActions>
            </PageSection>
          </Expandable>
        </Form>
      )}
    </div>
  );
};

export default DocketForm;
