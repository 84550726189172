import messages from './validators.messages';
import {
  MaxVolume,
  DensityRange,
  MaxMass,
  Aircraft,
} from '../../models/AircraftData';
import { isUSPortSelected } from '../isUSPortSelected';
import { PortConfiguration } from '../../models/UIConfigData';

// Allows positive and negative numbers of up to 15 digits and 5 decimal places.
// Allows numbers in comma-separated format up to 6 integers (e.g. -120,000.34500)
const isValidNumberString = (value: string): boolean =>
  /^\-?\.?(([0-9]{1,3}(,[0-9]{3}))|([0-9]{1,15}))(\.[0-9]{1,5})?$/.test(value);

const greaterThanTwoDecimalPlaces = (value: string) => /\.[0-9]{3}/.test(value);
const greaterThanThreeDecimalPlaces = (value: string) =>
  /\.[0-9]{4}/.test(value);

const createMaxFuelRequestedValidator = (maxMass: MaxMass) => (
  value: string
): undefined | string => {
  if (greaterThanTwoDecimalPlaces(value)) {
    return messages.decimalPlaces(2);
  }

  if (!isValidNumberString(value)) {
    return messages.invalidNumber;
  }

  const numberValue = parseFloat(value);

  if (numberValue <= 0) {
    return messages.greaterThanZero;
  }

  return numberValue > maxMass.max
    ? messages.maxFuelWeight(maxMass)
    : undefined;
};

const createMaxPreTransactionFuelValidator = (maxMass: MaxMass) => (
  value: string
): undefined | string => {
  if (greaterThanTwoDecimalPlaces(value)) {
    return messages.decimalPlaces(2);
  }

  if (!isValidNumberString(value)) {
    return messages.invalidNumber;
  }

  const numberValue = parseFloat(value);

  if (numberValue < 0) {
    return messages.nonNegative;
  }

  return numberValue > maxMass.max
    ? messages.maxFuelWeight(maxMass)
    : undefined;
};

const createMaxVolumeTransferredValidator = (maxVolume: MaxVolume) => (
  value: string
): undefined | string => {
  if (greaterThanTwoDecimalPlaces(value)) {
    return messages.decimalPlaces(2);
  }

  if (!isValidNumberString(value)) {
    return messages.invalidNumber;
  }

  const numberValue = parseFloat(value);

  if (numberValue <= 0) {
    return messages.greaterThanZero;
  }

  return numberValue > maxVolume.max
    ? messages.maxFuelVolume(maxVolume)
    : undefined;
};

const createDensityRangeValidator = (densityRange: DensityRange) => (
  value: string
): undefined | string => {
  if (greaterThanThreeDecimalPlaces(value)) {
    return messages.decimalPlaces(3);
  }

  if (!isValidNumberString(value)) {
    return messages.invalidNumber;
  }

  const floatValue = parseFloat(value);

  if (
    floatValue < densityRange.lowRange ||
    floatValue > densityRange.highRange
  ) {
    return messages.densityRange(densityRange);
  }

  return undefined;
};

const isValidFlightNumber = (value: string): undefined | string => {
  if (value.toUpperCase() === ('NZ' || '')) {
    return messages.noValue;
  }

  // Allows 'NZ' followed by 1 - 4 numbers, with an optional trailing letter (case insensitive)
  const regex = /^[a-z]{2}([0-9]{1,4})([a-z])?$/i;
  if (!regex.test(value)) {
    return messages.invalidFlightNumberFormat;
  }

  const airlinePrefix = value.substring(0, 2).toUpperCase();
  if (airlinePrefix !== 'NZ') {
    return messages.invalidAirline;
  }

  return undefined;
};

const isNumeric = (value: string): undefined | string => {
  const regex = /[^\d]+/g;
  if (regex.test(value)) {
    return messages.notNumeric;
  }

  return undefined;
};

const isAlphaNumeric = (value: string): undefined | string => {
  const regex = /[^\w\d]+/g;
  if (regex.test(value)) {
    return messages.notAlphaNumeric;
  }

  return undefined;
};

const createAircraftRegistrationValidator = (
  aircraftList: Aircraft[],
  port: string,
  portList: PortConfiguration[]
) => (registration: string) => {
  if (registration === 'ZK') {
    return messages.noValue;
  }

  const matchingAircraft = aircraftList.filter(
    (aircraft: Aircraft) =>
      aircraft.aircraftRegistration.toLowerCase() === registration.toLowerCase()
  );

  if (matchingAircraft.length === 0) {
    return messages.registrationDoesNotExist;
  }

  if (isUSPortSelected(portList, port)) {
    const usBasedUom = matchingAircraft.filter((aircraft: Aircraft) => {
      const densityUomMatch = aircraft.densityRangeConfigurations.filter(
        d => d.unitOfMeasure === 'KILOGRAMS PER GALLON'
      );

      const massUomMatch = aircraft.tankCapacityConfigurations.filter(
        t => t.unitOfMeasure === 'TONS'
      );

      const volumeUomMatch = aircraft.volumeTransferredConfigurations.filter(
        v => v.unitOfMeasure === 'GALLONS'
      );

      return (
        densityUomMatch.length > 0 &&
        massUomMatch.length > 0 &&
        volumeUomMatch.length > 0
      );
    });

    if (usBasedUom.length === 0) {
      return messages.noUSUomConfig;
    }
  }

  return undefined;
};

const isValidCreatedBy = (value: string): undefined | string => {
  const regex = /[^a-z\s,.'-]+/gi;
  if (regex.test(value)) {
    return messages.invalidCreatedBy;
  }

  return undefined;
};

const isValidAirportCode = (
  portList: PortConfiguration[],
  value: string
): undefined | string => {
  const port = portList.filter((item: any) => item.value === value);
  if (port.length === 0) {
    return messages.invalidAirportCode;
  }

  return undefined;
};

const isValidTailNumber = (value: string): undefined | string => {
  const regex = /^[0-9a-z]{5}$/i;
  if (!regex.test(value)) {
    return messages.invalidTailNumber;
  }

  return undefined;
};

const minimumCharacterLimit = (
  value: string,
  limit: number
): undefined | string => {
  if (value.length < limit) {
    return messages.minimumCharacterLimit(limit);
  }

  return undefined;
};

const exceedsCharacterLimit = (
  value: string,
  limit: number
): undefined | string => {
  if (value.length > limit) {
    return messages.exceedsCharacterLimit(limit);
  }

  return undefined;
};

const containsBannedWord = (value: string): undefined | string => {
  const lowerCaseValue = value.toLowerCase();
  const bombRegex = /bomb|b0mb|explo|dynamite|ammunition|missile|blast|torpedo|grenade|mine|projectile|rocket|bullet|blast|cannon|gun|knife|fire|snake/g;
  if (bombRegex.test(lowerCaseValue)) {
    return messages.invalidDocketNumber;
  }
  return undefined;
};

const isValidDocketNumber = (value: string): undefined | string =>
  exceedsCharacterLimit(value, 20) ||
  isAlphaNumeric(value) ||
  containsBannedWord(value) ||
  minimumCharacterLimit(value, 5);

const isValidDocketNumberSearch = (value: string): undefined | string =>
  exceedsCharacterLimit(value, 20) || isAlphaNumeric(value);

export default {
  createMaxFuelRequestedValidator,
  createMaxPreTransactionFuelValidator,
  createMaxVolumeTransferredValidator,
  createDensityRangeValidator,
  isValidFlightNumber,
  isNumeric,
  isAlphaNumeric,
  createAircraftRegistrationValidator,
  isValidAirportCode,
  isValidTailNumber,
  isValidCreatedBy,
  isValidDocketNumber,
  isValidDocketNumberSearch,
};
