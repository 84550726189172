import User, { Role } from '../../models/User';
import { initialDocketSearchState } from '../../state/initialState';
import { SearchParameters } from '../../models/DocketSearchParams';

const getDefaultSearchParams = (user: User): SearchParameters => {
  const result: SearchParameters = initialDocketSearchState;
  if (
    user.role === Role.fueller ||
    user.role === Role.AOC ||
    user.role === Role.engineer
  ) {
    result.dateRange = {
      from: '',
      to: '',
    };
  }
  if (user.role === Role.fueller) {
    result.createdBy = user.name;
  }
  return result;
};

export default getDefaultSearchParams;
