import React from 'react';

import Alert from '@airnz/ui/Alert';

interface ViewDocketErrorMessageProps {
  docketId: string;
  errorCode: number;
}

const ViewDocketErrorMessage = ({
  docketId,
  errorCode,
}: ViewDocketErrorMessageProps) => {
  const defaultMessage = (
    <>
      There was a problem fetching docket <b>{docketId}</b>. Please try again
      later, or contact the Airport Operations Controllers at your airport.
    </>
  );
  let errorMessage;

  switch (errorCode) {
    case 400:
      errorMessage = (
        <>
          <b>{docketId}</b> is not a valid docket id.
        </>
      );
      break;
    case 401:
    case 403:
      errorMessage = (
        <>
          You don’t have permission to view docket <b>{docketId}</b>.
        </>
      );
      break;
    case 404:
      errorMessage = (
        <>
          Docket <b>{docketId}</b> couldn’t be found.
        </>
      );
      break;
    default:
      errorMessage = defaultMessage;
  }

  return (
    <Alert type="danger" minimal>
      {errorMessage}
    </Alert>
  );
};

export default ViewDocketErrorMessage;
