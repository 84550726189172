import React from 'react';

import { createBem } from '@airnz/ui/bem';
import ReadOnlyField from '@airnz/ui/ReadOnlyField';
import { IntlDateTimeLocal } from '@airnz/ui/intl';

import DocketData from '../../models/DocketData';
import getUnit from '../../utils/getUnit';
import { formatWithCommas } from '../../utils/formatNumbers';

import './DocketDetails.scss';

interface DocketDetailsProps {
  data: DocketData;
}

const bem = createBem('fuel-DocketDetails');

const DocketDetails = ({ data }: DocketDetailsProps) => {
  const {
    createdBy,
    createdDateTimeLocal,
    airportCode,
    aircraftRegistration,
    flightNumber,
    totalFuelRequested,
    totalFuelRequestedUnitOfMeasure,
    volumeTransferred,
    volumeTransferredUnitOfMeasure,
    preTransactionFuel,
    preTransactionFuelUnitOfMeasure,
    density,
    densityUnitOfMeasure,
    fuelProvider,
  } = data;
  return (
    <div className={bem()}>
      <ReadOnlyField label="Created by" className={bem('createdBy')}>
        {createdBy}
      </ReadOnlyField>
      <ReadOnlyField label="Fuel provider" className={bem('fuelProvider')}>
        {fuelProvider}
      </ReadOnlyField>
      <ReadOnlyField label="Port" className={bem('port')}>
        {airportCode}
      </ReadOnlyField>
      <ReadOnlyField label="Date" className={bem('date')}>
        <IntlDateTimeLocal value={createdDateTimeLocal} clockMode="24h" />
      </ReadOnlyField>
      <ReadOnlyField
        label="Aircraft registration"
        className={bem('aircraftRegistration')}
      >
        {aircraftRegistration}
      </ReadOnlyField>
      <ReadOnlyField label="Flight number" className={bem('flightNumber')}>
        {flightNumber || '–'}
      </ReadOnlyField>
      <ReadOnlyField
        label="Total fuel requested"
        className={bem('totalFuelRequested')}
      >
        {`${formatWithCommas(totalFuelRequested)} ${getUnit(
          totalFuelRequestedUnitOfMeasure
        )}`}
      </ReadOnlyField>
      <ReadOnlyField
        label="Pre-transaction fuel"
        className={bem('preTransactionFuel')}
      >
        {`${formatWithCommas(preTransactionFuel)} ${getUnit(
          preTransactionFuelUnitOfMeasure
        )}`}
      </ReadOnlyField>
      <ReadOnlyField
        label="Volume delivered"
        className={bem('volumeTransferred')}
      >
        {`${formatWithCommas(volumeTransferred)} ${getUnit(
          volumeTransferredUnitOfMeasure
        )}`}
      </ReadOnlyField>
      <ReadOnlyField label="Density" className={bem('density')}>
        {`${density} ${getUnit(densityUnitOfMeasure)}`}
      </ReadOnlyField>
    </div>
  );
};
export default DocketDetails;
