export interface PortConfiguration {
  airportCode: string;
  timezone: string;
  countryCode: string;
  label: string;
}

export class FuelProvider {
  label: string;
  value: string;

  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }
}

export interface UIConfiguration {
  portConfigurations: PortConfiguration[];
  fuelProviders: FuelProvider[];
}

export const initialUIConfigState: UIConfiguration = {
  portConfigurations: [],
  fuelProviders: [],
};
