import React from 'react';

import HeaderLink from '@airnz/ui/HeaderLink';
import { createBem } from '@airnz/ui/bem';

import { newDocketPath, docketsPath } from '../../utils/routeConstants';
import User, { Role } from '../../models/User';
import createGtmEvent from '../../utils/createGtmEvent';
import history from '../../utils/history';

import './PageHeaderLinks.scss';

const bem = createBem('fuel-PageHeaderLinks');

export interface PageHeaderLinksProps {
  user: User;
}

const PageHeaderLinks = ({ user }: PageHeaderLinksProps) => {
  const links =
    user && user.role !== Role.none && user.role !== Role.unknown ? (
      <div className={bem()}>
        {user.role === 'fueller' ||
        user.role === 'support' ||
        user.role === 'engineer' ? (
          <HeaderLink
            onClick={() => {
              createGtmEvent('Header', 'Click link', 'New docket');
              history.push(newDocketPath);
            }}
            className={bem('headerLink', 'newDocket')}
          >
            New docket
          </HeaderLink>
        ) : null}
        <HeaderLink
          onClick={() => {
            createGtmEvent('Header', 'Click link', 'Dashboard');
            history.push(docketsPath);
          }}
          className={bem('headerLink', 'dashboard')}
        >
          Dashboard
        </HeaderLink>
      </div>
    ) : null;
  return links;
};

export default PageHeaderLinks;
