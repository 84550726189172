import React, { useState, useEffect, ReactNode } from 'react';
import AutocompleteField from '@airnz/ui/AutocompleteField';
import {
  AutocompleteInputValue,
  containsLowerCasedQuery,
} from '@airnz/ui/AutocompleteInput';
import { createBem, combineClassNames } from '@airnz/ui/bem';
import { PortConfiguration, UIConfiguration } from '../../models/UIConfigData';

const bem = createBem('fuel-AutoCompleteInput');

interface AutoCompleteOption {
  value: string;
  label: string;
}

interface AutocompleteInputProps {
  options: AutoCompleteOption[];
  onChange(newValue: any): void;
  label: ReactNode;
  className?: string;
  name?: string;
  required?: boolean;
  validator?(portList: PortConfiguration[], value: string): string | undefined;
  uiConfig: UIConfiguration;
}

const SearchAutocompleteInput = ({
  options,
  onChange,
  label,
  className,
  name,
  required,
  validator,
  uiConfig,
}: AutocompleteInputProps) => {
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<
    AutoCompleteOption[]
  >([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState<
    AutocompleteInputValue<AutoCompleteOption>
  >({ query: '', option: undefined });
  const [error, setError] = useState<string>('');

  const handleLocalChange = ({
    option,
    query,
  }: AutocompleteInputValue<AutoCompleteOption>) => {
    const filteredOptions = options.filter((optionItem: AutoCompleteOption) =>
      query
        ? containsLowerCasedQuery(optionItem.label, query) ||
          containsLowerCasedQuery(optionItem.value, query)
        : true
    );

    setAutoCompleteOptions(filteredOptions);
    setAutocompleteInputValue({ query, option });
  };

  useEffect(() => {
    onChange(
      autocompleteInputValue.option
        ? autocompleteInputValue.option.value
        : undefined
    );
  }, [autocompleteInputValue.option, onChange]);

  const validateInput = () => {
    if (
      autocompleteInputValue.query &&
      !autocompleteInputValue.option &&
      validator
    ) {
      const validatorMessage = validator(
        uiConfig.portConfigurations,
        autocompleteInputValue.query
      );
      setError(validatorMessage || '');
    } else {
      setError('');
    }
  };

  return (
    <AutocompleteField
      id={`${name}AutoComplete`}
      className={combineClassNames(bem(), className)}
      label={label}
      value={autocompleteInputValue}
      getInputValue={(option: AutoCompleteOption) => option.label}
      renderOption={(option: AutoCompleteOption) => option.label}
      options={autoCompleteOptions}
      onChange={handleLocalChange}
      required={required}
      onBlur={validateInput}
      error={error}
    />
  );
};

export default SearchAutocompleteInput;
