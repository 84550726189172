import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useContext,
} from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import PageBlock from '@airnz/ui/PageBlock';
import LoadingOverlay from '@airnz/ui/LoadingOverlay';
import { createBem } from '@airnz/ui/bem';
import Button from '@airnz/ui/Button';
import Alert from '@airnz/ui/Alert';
import Print from '@airnz/ui/icons/Print';
import PageHeading from '@airnz/ui/PageHeading';
import PageActions from '@airnz/ui/PageActions';
import Inverse from '@airnz/ui/Inverse';

import DocketData from '../../models/DocketData';
import DocketList from '../../components/DocketList';
import DocketSearch from '../../components/DocketSearch';
import CsvDownloadButton from '../../components/CsvDownloadButton';
import Paginator from '../../components/Paginator';
import printPage from '../../utils/printPage';
import { SortFieldValueType } from '../../components/SortField';
import sortDockets from '../../utils/sortDockets';
import {
  initialDocketSortState,
  initialPageState,
  pageSize,
} from '../../state/initialState';
import { Role } from '../../models/User';
import SearchUtils from '../../components/SearchUtils';
import { transformSearchParameters } from '../../utils/dataTransforms';
import { SearchParameters } from '../../models/DocketSearchParams';
import getDefaultSearchParams from '../../utils/getDefaultSearchParams';
import createGtmEvent from '../../utils/createGtmEvent';
import UIConfigContext from '../../state/UIConfigContext';
import UserContext from '../../state/UserContext';
import { useFuelDocketApiClient } from '../../utils/api/useFuelDocketApiClient';

import './DocketsPage.scss';

const bem = createBem('fuel-DocketsPage');

interface DocketsPageProps {
  docketData: DocketData[] | null;
  setDocketData: Dispatch<SetStateAction<DocketData[] | null>>;
}

const DocketsPage = ({ docketData, setDocketData }: DocketsPageProps) => {
  const uiConfig = useContext(UIConfigContext);
  const user = useContext(UserContext);
  const [sort, setSort] = useState<SortFieldValueType>(
    window.localStorage.getItem('fuel-sortDockets') || initialDocketSortState
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [page, setPage] = useState<number>(initialPageState);
  const [canShowMore, setCanShowMore] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [searchParams, setSearchParams] = useState<SearchParameters>(
    getDefaultSearchParams(user)
  );
  const client = useFuelDocketApiClient();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.Logout) {
      return;
    }

    const canSearchDates = user.role === 'support' || user.role === 'admin';
    // Update the 24-hour range timestamps for fueller, AOC and engineering users to avoid a 403 error.
    if (!canSearchDates) {
      setSearchParams(getDefaultSearchParams(user));
    }
    const searchParamObject = transformSearchParameters(
      searchParams,
      canSearchDates
    );
    setIsLoading(true);
    setPage(initialPageState);
    setCanShowMore(false);
    setErrorMessage('');

    const searchDockets = () =>
      client
        .fetchDockets(searchParamObject)
        .then(response => {
          if (response.fuelDockets.length) {
            setDocketData(response.fuelDockets);
          } else {
            setErrorMessage('No dockets could be found.');
            setDocketData([]);
          }
          setIsLoading(false);
          setCanShowMore(response.fuelDockets.length === pageSize);
        })
        .catch(error => {
          setIsLoading(false);
          setDocketData([]);
          let message;
          if (error && error.code) {
            message = getDocketListErrorMessage(error.code);
          } else {
            message = getDocketListErrorMessage();
          }
          setErrorMessage(message);
        });

    searchDockets();
  }, [searchParams, setDocketData, client, user, inProgress]);

  const getDocketListErrorMessage = (errorCode?: number) => {
    const defaultMessage = 'There was a problem fetching the docket list.';
    let result;
    switch (errorCode) {
      case 403:
        result = `You don’t have permission to view these fuel dockets.`;
        break;
      default:
        result = defaultMessage;
    }
    return result;
  };

  const fetchMore = () => {
    const initialPage = page;
    const nextPage = initialPage + 1;
    setPage(nextPage);
    setIsFetchingMore(true);
    const fetchMoreSearchParams = { ...searchParams, page: nextPage };
    const canSearchDates = user.role === 'support' || user.role === 'admin';
    return client
      .fetchDockets(
        transformSearchParameters(fetchMoreSearchParams, canSearchDates)
      )
      .then(response => {
        const allDockets = [...(docketData || []), ...response.fuelDockets];
        setDocketData(allDockets);
        setIsFetchingMore(false);
        setCanShowMore(response.fuelDockets.length === pageSize);
      })
      .catch(() => {
        setErrorMessage('There was a problem fetching more dockets.');
        setIsFetchingMore(false);
      });
  };

  const changeSort = (value: SortFieldValueType) => {
    setSort(value);
    if (docketData) {
      setDocketData(sortDockets(docketData, value));
      if (window.localStorage) {
        window.localStorage.setItem('fuel-sortDockets', value);
      }
    }
    createGtmEvent('Dashboard', 'Sort dockets', value);
  };

  const sortedDockets = docketData ? sortDockets(docketData, sort) : [];

  const canSearch = user.role === Role.support || user.role === Role.admin;

  return (
    <>
      <PageBlock className={bem()}>
        <PageHeading>Fuel docket dashboard</PageHeading>
        <LoadingOverlay open={isLoading} className={bem('loadingOverlay')} />
      </PageBlock>
      {docketData ? (
        <>
          {canSearch && (
            <Inverse>
              <PageBlock>
                <DocketSearch
                  filters={searchParams}
                  changeFilters={setSearchParams}
                  user={user}
                  uiConfig={uiConfig}
                />
              </PageBlock>
            </Inverse>
          )}
          <PageBlock>
            {!errorMessage && !isLoading && (
              <SearchUtils
                canSearch={canSearch}
                canSort={docketData.length > 1}
                onChangeSort={changeSort}
                sortValue={sort}
                role={user.role}
                searchParams={searchParams}
                className={bem('searchUtils')}
              />
            )}
            {docketData.length ? <DocketList dockets={sortedDockets} /> : null}
          </PageBlock>
        </>
      ) : null}
      <PageBlock>
        {!errorMessage && docketData && docketData.length && canShowMore ? (
          <PageActions>
            <Paginator
              onIncrement={() => {
                createGtmEvent('Dashboard', 'Click button', 'Show more');
                fetchMore();
              }}
              isFetchingMore={isFetchingMore}
            />
          </PageActions>
        ) : null}
        {docketData && docketData.length && user.role !== Role.fueller ? (
          <PageActions className={bem('pageActions')}>
            <Button
              onClick={() => {
                printPage();
                createGtmEvent('Dashboard', 'Click button', 'Print');
              }}
              icon={<Print />}
              ariaLabel="Print"
            >
              Print
            </Button>
            <CsvDownloadButton
              docketData={docketData}
              onClick={() => {
                createGtmEvent('Dashboard', 'Click button', 'Download');
              }}
            />
          </PageActions>
        ) : null}
        {errorMessage && !isLoading ? (
          <Alert type="warning" className={bem('alert')}>
            {errorMessage}
          </Alert>
        ) : null}
      </PageBlock>
    </>
  );
};

export default DocketsPage;
