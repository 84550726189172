import {
  Aircraft,
  DensityRange,
  MaxMass,
  MaxVolume,
} from '../models/AircraftData';
import { assertNever } from '@airnz/ui/type-utils';
import {
  actionCreators,
  Action,
} from '../components/DocketForm/aircraftRegistrationReducer';
import UnitOfMeasure from '../models/UnitOfMeasure';

export const setValidationValues = (
  type: 'mass' | 'volume' | 'density',
  unitOfMeasure: UnitOfMeasure,
  aircraft: Aircraft
): Action => {
  switch (type) {
    case 'mass':
      const massValue: MaxMass = aircraft.tankCapacityConfigurations.filter(
        (maxMass: MaxMass) => maxMass.unitOfMeasure === unitOfMeasure
      )[0];
      return actionCreators.setMaxMass(massValue);
    case 'density':
      const densityRangeValue: DensityRange = aircraft.densityRangeConfigurations.filter(
        (densityRange: DensityRange) =>
          densityRange.unitOfMeasure === unitOfMeasure
      )[0];

      if (!densityRangeValue) {
        throw new Error(
          'The chosen density unit of measurement does not exist on the current aircraft.'
        );
      }

      return actionCreators.setDensityRange(densityRangeValue);
    case 'volume':
      const maxVolume: MaxVolume = aircraft.volumeTransferredConfigurations.filter(
        (densityRange: MaxVolume) =>
          densityRange.unitOfMeasure === unitOfMeasure
      )[0];

      if (!maxVolume) {
        throw new Error(
          'The chosen volume unit of measurement does not exist on the current aircraft.'
        );
      }
      return actionCreators.setMaxVolume(maxVolume);
    default:
      return assertNever(type);
  }
};

export default setValidationValues;
