import { MaxVolume, DensityRange, MaxMass } from '../../models/AircraftData';

import getUnit from '../../utils/getUnit';

export default {
  maxFuelWeight: (maxMass: MaxMass) =>
    `Please enter a value equal to or less than ${maxMass.max} ${getUnit(
      maxMass.unitOfMeasure
    )}.`,
  maxFuelVolume: (maxVolume: MaxVolume) =>
    `Please enter a value equal to or less than ${maxVolume.max} ${getUnit(
      maxVolume.unitOfMeasure
    )}.`,
  densityRange: (densityRange: DensityRange) =>
    `Please enter a value within the range of ${densityRange.lowRange} and ${
      densityRange.highRange
    } ${getUnit(densityRange.unitOfMeasure)}.`,
  registrationDoesNotExist: `Not a supported aircraft registration.`,
  invalidNumber:
    'Please enter a valid number format (a number with up to two decimal places).',
  decimalPlaces: (n: number) =>
    `Please enter a number with no more than ${n} decimal places`,
  invalidNumberThreeDigits: 'Please enter a valid number format.',
  noValue: 'Please fill out this field.',
  noUSUomConfig:
    'Please enter an aircraft registration that supports unit of measurement for US based ports.', // Controlled by Form @airnz-ui Form component. Cannot be changed.
  invalidFlightNumberFormat: 'Please enter a valid flight number.',
  invalidAirline: 'Please enter an Air New Zealand flight number.',
  greaterThanZero: 'Please enter a value greater than zero.',
  nonNegative: 'Please enter a positive number',
  notNumeric: 'Please enter only numbers.',
  notAlphaNumeric: 'Please enter only numbers or letters.',
  invalidAirportCode: 'Please enter an airport.',
  invalidTailNumber: 'Please enter a valid aircraft registration.',
  invalidCreatedBy:
    'Name contains characters which are not supported in this application.',
  exceedsCharacterLimit: (limit: number) =>
    `Exceeds the ${limit} character limit.`,
  invalidDocketNumber: 'Invalid docket number.',
  minimumCharacterLimit: (limit: number) =>
    `Please enter a value of at least ${limit} characters.`,
};
