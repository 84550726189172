import React from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import { combineClassNames, createBem } from '@airnz/ui/bem';
import ChevronRight from '@airnz/ui/icons/ChevronRight';
import { IntlTimeLocal } from '@airnz/ui/intl';

import { createDocketPath } from '../../utils/routeConstants';
import DocketData from '../../models/DocketData';
import getUnit from '../../utils/getUnit';
import createGtmEvent from '../../utils/createGtmEvent';
import { formatWithCommas } from '../../utils/formatNumbers';

import './DocketTable.scss';

const bem = createBem('fuel-DocketTable');

interface DocketTableProps {
  dockets: DocketData[];
  className?: string;
}

const DocketTable = ({ dockets, className }: DocketTableProps) => (
  <div className={combineClassNames(bem(), className)}>
    <div className={bem('thead')}>
      <div className={bem('tr')}>
        <div className={bem('th', 'createdDate')}>Created at</div>
        <div className={bem('th', 'docketNumber')}>Docket number</div>
        <div className={bem('th', 'createdBy')}>Created by</div>
        <div className={bem('th', 'fuelProvider')}>Fuel provider</div>
        <div className={bem('th', 'port')}>Port</div>
        <div className={bem('th', 'aircraftRegistration')}>
          Aircraft registration
        </div>
        <div className={bem('th', 'flightNumber')}>Flight number</div>
        <div className={bem('th', 'volumeTransferred')}>Volume delivered</div>
        <div className={combineClassNames(bem('th', 'chevron'))} />
      </div>
    </div>
    <div className={bem('tbody')}>
      {Boolean(dockets.length) &&
        dockets.map((docket: DocketData) => (
          <Link
            to={{
              pathname: createDocketPath(docket.fuelDocketId),
            }}
            className={bem('link', docket.docketNumber)}
            key={`row-${docket.fuelDocketId}`}
            onClick={() =>
              createGtmEvent(
                'Dashboard',
                'Click on single docket',
                docket.fuelDocketId
              )
            }
          >
            <div className={bem('tr')}>
              <div className={bem('td', 'createdDate')}>
                <IntlTimeLocal
                  value={docket.createdDateTimeLocal}
                  clockMode="24h"
                />
                <div>{format(docket.createdDateTimeLocal, 'DD/MM/YY')}</div>
              </div>
              <div className={bem('td', 'docketNumber')}>
                {docket.docketNumber}
              </div>
              <div className={bem('td', 'createdBy')}>{docket.createdBy}</div>
              <div className={bem('td', 'fuelProvider')}>
                {docket.fuelProvider}
              </div>
              <div className={bem('td', 'port')}>{docket.airportCode}</div>
              <div className={bem('td', 'aircraftRegistration')}>
                {docket.aircraftRegistration}
              </div>
              <div className={bem('td', 'flightNumber')}>
                {docket.flightNumber}
              </div>
              <div className={bem('td', 'volumeTransferred')}>
                {`${formatWithCommas(docket.volumeTransferred)} ${getUnit(
                  docket.volumeTransferredUnitOfMeasure
                )}`}
              </div>
              <div className={combineClassNames(bem('td', 'chevron'))}>
                <ChevronRight className={combineClassNames(bem('chevron'))} />
              </div>
            </div>
          </Link>
        ))}
    </div>
  </div>
);

export default DocketTable;
