import React from 'react';

import { createBem } from '@airnz/ui/bem';
import Button from '@airnz/ui/Button';

import './Paginator.scss';

const bem = createBem('fuel-Paginator');

interface PaginatorProps {
  onIncrement(): void;
  isFetchingMore: boolean;
}

const Paginator = ({ onIncrement, isFetchingMore }: PaginatorProps) => (
  <Button
    primary
    className={bem()}
    onClick={onIncrement}
    inProgress={isFetchingMore}
  >
    Show more
  </Button>
);

export default Paginator;
