import React from 'react';
import { format, isValid, isSameDay } from 'date-fns';

import { combineClassNames, createBem } from '@airnz/ui/bem';
import Paragraph from '@airnz/ui/Paragraph';

import { SearchParameters } from '../../models/DocketSearchParams';

export interface SearchSummaryProps {
  filters: SearchParameters;
  className?: string;
}

const bem = createBem('fuel-SearchSummary');

const SearchSummary = ({ filters, className }: SearchSummaryProps) => {
  const formatFilterNameForCopy = (filterKey: string): string => {
    let result;
    const dateFormat = 'D MMM YYYY';
    switch (filterKey) {
      case 'createdBy':
      case 'airportCode':
      case 'fuelProvider':
        result = filters[filterKey];
        break;
      case 'flightNumber':
      case 'aircraftRegistration':
        result = filters[filterKey].toUpperCase();
        break;
      case 'dateRange':
        const from = filters[filterKey].from;
        const to = filters[filterKey].to;
        if (isValid(new Date(from))) {
          if (isValid(new Date(to))) {
            if (isSameDay(from, to)) {
              result = format(from, dateFormat);
            } else {
              result = `${format(from, dateFormat)} to ${format(
                to,
                dateFormat
              )}`;
            }
          } else {
            result = format(from, dateFormat);
          }
        } else {
          result = '';
        }
        break;
      case 'docketNumber':
        result = `Docket ${filters[filterKey]}`;
        break;
      default:
        result = '';
    }
    return result.slice(0, 1).toUpperCase() + result.slice(1);
  };

  const renderSearchParams = () => {
    const searchKeys = Object.keys(filters).filter(
      (searchKey: string) =>
        searchKey !== 'page' &&
        searchKey !== 'pageSize' &&
        Boolean(filters[searchKey as keyof SearchParameters])
    );

    const searchParamsArray: string[] = [];
    let filterString = '';
    if (searchKeys.length) {
      searchKeys.forEach(filterKey => {
        searchParamsArray.push(formatFilterNameForCopy(filterKey));
      });
      const filteredArray = searchParamsArray.filter(
        (value: string) => value !== ''
      );
      if (filteredArray.length) {
        filterString = `${filteredArray.join(', ')}.`;
      }
    }

    return Boolean(filterString) ? `Search results: ${filterString}` : null;
  };

  return (
    <Paragraph className={combineClassNames(bem(), className)}>
      {renderSearchParams()}
    </Paragraph>
  );
};

export default SearchSummary;
