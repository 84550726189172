import { PortConfiguration } from '../models/UIConfigData';

const isUSPortSelected = (portList: PortConfiguration[], port: string) => {
  const matchingPort = portList
    .filter(p => p.countryCode === 'US')
    .filter(p => p.airportCode === port);
  return matchingPort.length > 0;
};

export { isUSPortSelected };
