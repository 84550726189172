import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Button from '@airnz/ui/Button';
import PageBlock from '@airnz/ui/PageBlock';
import PageHeading from '@airnz/ui/PageHeading';
import { createBem } from '@airnz/ui/bem';
import Alert from '@airnz/ui/Alert';
import ModalDialog from '@airnz/ui/ModalDialog';

import DocketForm from '../../components/DocketForm';
import LinkButton from '../../components/LinkButton';
import DocketsEditAndCreateErrorMessage from '../../components/DocketsEditAndCreateErrorMessage';
import {
  docketsPath,
  createDocketPath,
  notFoundPath,
} from '../../utils/routeConstants';
import DocketData from '../../models/DocketData';
import { Role } from '../../models/User';
import ModalData from '../../models/ModalData';
import { initialModalState } from '../../state/initialState';
import createGtmEvent from '../../utils/createGtmEvent';
import UIConfigContext from '../../state/UIConfigContext';
import UserContext from '../../state/UserContext';
import { useFuelDocketApiClient } from '../../utils/api/useFuelDocketApiClient';

interface EditDocketProps extends RouteComponentProps<{}, {}, DocketData> {}

const bem = createBem('fuel-EditDocket');

const EditDocket = ({ location, history }: EditDocketProps) => {
  const [modalData, setModalData] = useState<ModalData>(initialModalState);
  const [open, toggleOpen] = useState(false);
  const uiConfig = useContext(UIConfigContext);
  const user = useContext(UserContext);
  const client = useFuelDocketApiClient();

  const closeModal = () => toggleOpen(false);

  useEffect(() => {
    if (!location.state) {
      const docketId = location.pathname.replace('/edit-docket/', '');
      const newPath = docketId ? createDocketPath(docketId) : notFoundPath;
      history.push(newPath);
    }
  }, [location, history]);

  const resubmitHandler = (formData: DocketData) => {
    // Only include the fields that the user is allowed to edit
    let data: Partial<DocketData> = {};
    // An empty flight number should be sent to the API as "" rather than null or undefined to allow a flight number to be deleted when editing
    const cleanFlightNumber = (flightNumber: string) =>
      !flightNumber || (flightNumber && flightNumber.toUpperCase() === 'NZ')
        ? ''
        : flightNumber;
    switch (user.role) {
      case Role.AOC:
        data = {
          aircraftRegistration: formData.aircraftRegistration,
          flightNumber: cleanFlightNumber(formData.flightNumber),
        };
        break;
      case Role.fueller:
      case Role.engineer:
        data = {
          ...formData,
          flightNumber: cleanFlightNumber(formData.flightNumber),
        };
        break;
      case Role.support:
        data = {
          ...formData,
          flightNumber: cleanFlightNumber(formData.flightNumber),
        };
        delete data.fuelProvider;
        break;
      default:
    }
    delete data.fuelDocketId;
    delete data.fuelDocketType;
    delete data.createdBy;
    delete data.createdDateTimeLocal;
    delete data.updatedBy;

    return client
      .editDocket({
        fuelDocketId: formData.fuelDocketId,
        ...data,
      })
      .then(response => {
        toggleOpen(true);
        setModalData({
          heading: (
            <div className={bem('dialogHeader', 'success')}>Thank you</div>
          ),
          body: (
            <Alert
              minimal
              type="success"
              className={bem('dialogBody', 'success')}
            >
              Docket <b>{formData.docketNumber}</b> has been successfully
              resubmitted.
            </Alert>
          ),
          actions: (
            <>
              <LinkButton
                primary
                to={createDocketPath(response.fuelDocketId)}
                className={bem('primaryModalButton')}
                onClick={() =>
                  createGtmEvent(
                    'Edit docket',
                    'Click modal button',
                    'View docket'
                  )
                }
              >
                View docket
              </LinkButton>
              <LinkButton
                to={docketsPath}
                className={bem('secondaryModalButton')}
                onClick={() =>
                  createGtmEvent(
                    'Edit docket',
                    'Click modal button',
                    'Go to the dashboard'
                  )
                }
              >
                Go to the dashboard
              </LinkButton>
            </>
          ),
        });

        createGtmEvent(
          'Edit docket',
          'Successfully edited docket',
          `Role: ${user.role}, port: ${
            formData.airportCode
          }, fuelProvider: ${String(formData.fuelProvider)}`
        );
      })
      .catch(error => {
        toggleOpen(true);
        setModalData({
          heading: (
            <div className={bem('dialogHeader', 'error')}>
              Something went wrong
            </div>
          ),
          body: (
            <DocketsEditAndCreateErrorMessage
              docketNumber={formData.docketNumber}
              errorDetails={error}
              mode="edit"
              user={user}
            />
          ),
          actions: (
            <Button
              onClick={() => {
                closeModal();
                createGtmEvent('Edit docket', 'Close modal');
              }}
              primary
              className={bem('primaryModalButton')}
            >
              Close
            </Button>
          ),
        });

        createGtmEvent(
          'Edit docket',
          'Error editing docket',
          `Role: ${user.role}, port: ${
            formData.airportCode
          }, fuelProvider: ${String(formData.fuelProvider)}`
        );
      });
  };

  return location.state?.docketNumber ? (
    <PageBlock className={bem()}>
      <PageHeading>{`Edit docket ${location.state.docketNumber}`}</PageHeading>
      <DocketForm
        submitHandler={resubmitHandler}
        initialDocketState={location.state}
        mode={
          user.role === 'fueller' ||
          user.role === 'support' ||
          user.role === 'engineer'
            ? 'fullEdit'
            : 'restrictedEdit'
        }
        user={user}
        getAircraftDetailsConfig={client.getAircraftDetailsConfig}
        uiConfig={uiConfig}
      />
      <ModalDialog
        onClose={() => {
          closeModal();
          createGtmEvent('Edit docket', 'Close modal');
        }}
        heading={modalData.heading}
        actions={modalData.actions}
        open={open}
      >
        {modalData.body}
      </ModalDialog>
    </PageBlock>
  ) : null;
};

export default EditDocket;
