import { assertNever } from '@airnz/ui/type-utils';
import {
  Aircraft,
  AircraftConfigurationResponseData,
  MaxVolume,
  DensityRange,
  MaxMass,
} from '../../models/AircraftData';
import { PortConfiguration } from '../../models/UIConfigData';
import { transformAircraftDetails } from '../../utils/dataTransforms';
import validators from '../../utils/validators';

export enum Actions {
  setAircraftDetailsList = 'setAircraftDetailsList',
  setAircraftDetailsFetchError = 'setAircraftDetailsFetchError',
  setAircraftValidator = 'setAircraftValidator',
  setSelectedAircraft = 'setSelectedAircraft',
  setDensityRange = 'setDensityRange',
  setMaxVolume = 'setMaxVolume',
  setMaxMass = 'setMaxMass',
}

type AircraftValidator = (registration: string) => string | undefined;

export type Action =
  | {
      type: Actions.setAircraftDetailsList;
      aircraftDetailsList: Aircraft[];
    }
  | { type: Actions.setAircraftDetailsFetchError; error: Error }
  | { type: Actions.setAircraftValidator; validator: AircraftValidator }
  | {
      type: Actions.setSelectedAircraft;
      aircraft?: Aircraft;
    }
  | {
      type: Actions.setDensityRange;
      densityRange: DensityRange;
    }
  | {
      type: Actions.setMaxVolume;
      maxVolume: MaxVolume;
    }
  | {
      type: Actions.setMaxMass;
      maxMass: MaxMass;
    };

interface State {
  aircraftDetailsList: Aircraft[];
  aircraftDetailsFetchError?: Error;
  aircraftValidator?: AircraftValidator;
  selectedAircraft?: Aircraft;
  densityRange?: DensityRange;
  maxVolume?: MaxVolume;
  maxMass?: MaxMass;
}

export const initialState = {
  aircraftDetailsList: [],
};

export const actionCreators = {
  setAircraftDetailsList: (
    aircraftTypeData: AircraftConfigurationResponseData
  ): Action => ({
    type: Actions.setAircraftDetailsList,
    aircraftDetailsList: transformAircraftDetails(aircraftTypeData),
  }),
  setAircraftDetailsFetchError: (error: Error): Action => ({
    type: Actions.setAircraftDetailsFetchError,
    error,
  }),
  setAircraftValidator: (
    aircraftDetailsList: Aircraft[],
    port: string,
    portList: PortConfiguration[]
  ): Action => ({
    type: Actions.setAircraftValidator,
    validator: validators.createAircraftRegistrationValidator(
      aircraftDetailsList,
      port,
      portList
    ),
  }),
  setSelectedAircraft: (aircraft?: Aircraft): Action => ({
    type: Actions.setSelectedAircraft,
    aircraft,
  }),
  setDensityRange: (densityRange: DensityRange): Action => ({
    type: Actions.setDensityRange,
    densityRange,
  }),
  setMaxVolume: (maxVolume: MaxVolume): Action => ({
    type: Actions.setMaxVolume,
    maxVolume,
  }),
  setMaxMass: (maxMass: MaxMass): Action => ({
    type: Actions.setMaxMass,
    maxMass,
  }),
};

const aircraftRegistrationReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.setAircraftDetailsList:
      return { ...state, aircraftDetailsList: action.aircraftDetailsList };
    case Actions.setAircraftDetailsFetchError:
      return { ...state, aircraftDetailsFetchError: action.error };
    case Actions.setAircraftValidator:
      return { ...state, aircraftValidator: action.validator };
    case Actions.setSelectedAircraft:
      return { ...state, selectedAircraft: action.aircraft };
    case Actions.setDensityRange:
      return { ...state, densityRange: action.densityRange };
    case Actions.setMaxVolume:
      return { ...state, maxVolume: action.maxVolume };
    case Actions.setMaxMass:
      return { ...state, maxMass: action.maxMass };
    default:
      return assertNever(action);
  }
};

export default aircraftRegistrationReducer;
