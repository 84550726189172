import React from 'react';

import { Message } from '@airnz/ui/intl';
import PageHeading from '@airnz/ui/PageHeading';
import PageBlock from '@airnz/ui/PageBlock';
import PageActions from '@airnz/ui/PageActions';
import PageDescription from '@airnz/ui/PageDescription';
import { createBem } from '@airnz/ui/bem';
import ButtonLink from '@airnz/ui/ButtonLink';

import { rootPath } from '../../utils/routeConstants';

import messages from './NotFound.messages';

const bem = createBem('fuel-NotFound');

const NotFound = () => (
  <PageBlock className={bem()}>
    <PageHeading>
      <Message {...messages.header} />
    </PageHeading>
    <PageDescription>
      <Message {...messages.description} />
    </PageDescription>
    <PageActions>
      <ButtonLink to={rootPath}>
        <Message {...messages.button} />
      </ButtonLink>
    </PageActions>
  </PageBlock>
);

export default NotFound;
