import React from 'react';

import CommaSeparated from '@airnz/ui/CommaSeparated';

import { Organisation } from '../../models/User';

export const getOrganisations = (
  orgs: Organisation | Organisation[]
): string | JSX.Element =>
  typeof orgs === 'string' ? (
    orgs
  ) : (
    <CommaSeparated values={orgs} lastValueSeparator="and" />
  );

export const getPorts = (ports: string[] | undefined): string | JSX.Element =>
  ports && ports.length ? (
    <CommaSeparated values={ports} lastValueSeparator="and" />
  ) : (
    'unknown port'
  );
