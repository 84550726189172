import { SearchParameters } from '../models/DocketSearchParams';

const formatSearchParamsForAnalytics = (params: SearchParameters) => {
  const result: Partial<SearchParameters> = { ...params };

  delete result.page;
  delete result.pageSize;
  if (result.dateRange && Object.keys(result.dateRange).length === 0) {
    delete result.dateRange;
  }

  Object.keys(result).forEach(param => {
    if (!result[param as keyof SearchParameters]) {
      delete result[param as keyof SearchParameters];
    }
  });

  return JSON.stringify(result);
};

export default formatSearchParamsForAnalytics;
