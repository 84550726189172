import { Configuration } from '@azure/msal-browser';

import { appConfig as env } from '../config/env';

export const msalConfig: Configuration = {
  auth: {
    clientId: env.clientId,
    authority: `https://${env.authorityBase}/${env.authorityExtension}/${env.policyName}`,
    redirectUri: env.redirectUri,
    postLogoutRedirectUri: env.redirectUri,
    knownAuthorities: [env.authorityBase],
  },
};

export const loginRequest = {
  scopes: [env.scope],
};
