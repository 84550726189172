import { format, endOfDay, startOfDay } from 'date-fns';
import { SearchParameters, DateRange } from '../models/DocketSearchParams';
import {
  Aircraft,
  AircraftTypeConfiguration,
  AircraftConfigurationResponseData,
} from '../models/AircraftData';

const getStartOfDay = (date: string): string =>
  format(startOfDay(date), 'YYYY-MM-DDTHH:mm:ss');

const getEndOfDay = (date: string): string =>
  format(endOfDay(date), 'YYYY-MM-DDTHH:mm:ss');

const transformDates = (
  dateRange: DateRange
): { startDateTimeLocal: string; endDateTimeLocal: string } => {
  const result = {
    startDateTimeLocal: '',
    endDateTimeLocal: '',
  };
  if (dateRange.from && !dateRange.to) {
    result.startDateTimeLocal = getStartOfDay(dateRange.from);
    result.endDateTimeLocal = getEndOfDay(dateRange.from);
  } else if (!dateRange.from && dateRange.to) {
    result.startDateTimeLocal = getStartOfDay(dateRange.to);
    result.endDateTimeLocal = getEndOfDay(dateRange.to);
  } else {
    result.startDateTimeLocal = getStartOfDay(dateRange.from);
    result.endDateTimeLocal = getEndOfDay(dateRange.to);
  }
  return result;
};

/**
 * Admin and support roles can search by date range, so the dates should go from the start of the first day to the end of the last day of the range.
 * Fuellers and AOC roles get the past 24 hours, so these shouldn't be transformed to the start/end of the day.
 */
export const transformSearchParameters = (
  params: SearchParameters,
  searchDates?: boolean
) => {
  const transformedParams = stripOutEmptySearchParams(params);
  if (
    transformedParams.dateRange &&
    (transformedParams.dateRange.to || transformedParams.dateRange.from)
  ) {
    if (searchDates) {
      const startEndDates = transformDates(params.dateRange);
      transformedParams.startDateTimeLocal = startEndDates.startDateTimeLocal;
      transformedParams.endDateTimeLocal = startEndDates.endDateTimeLocal;
    } else {
      transformedParams.startDateTimeLocal = params.dateRange.from;
      transformedParams.endDateTimeLocal = params.dateRange.to;
    }
  }
  delete transformedParams.dateRange;
  return transformedParams;
};

const stripOutEmptySearchParams = (params: SearchParameters) => {
  const result: { [key: string]: any } = {};

  Object.keys(params).forEach((param: string) => {
    const paramValue = params[param as keyof SearchParameters];
    if (paramValue !== (undefined || '')) {
      result[param] = paramValue;
    }
  });

  return result;
};

export const transformAircraftDetails = (
  details: AircraftConfigurationResponseData
) => {
  if (!details.aircraftTypeFuelConfigurations) {
    throw new Error(
      'The format of the provided aircraft configuration data is invalid.'
    );
  }

  return details.aircraftTypeFuelConfigurations.reduce<Aircraft[]>(
    (acc: Aircraft[], aircraftTypeDetails: AircraftTypeConfiguration) => [
      ...acc,
      ...aircraftTypeDetails.aircraftRegistrations.map(
        (registration: string) => ({
          aircraftRegistration: registration,
          tankCapacityConfigurations:
            aircraftTypeDetails.tankCapacityConfigurations,
          volumeTransferredConfigurations:
            aircraftTypeDetails.volumeTransferredConfigurations,
          densityRangeConfigurations:
            aircraftTypeDetails.densityRangeConfigurations,
          aircraftName: aircraftTypeDetails.aircraftName,
        })
      ),
    ],
    []
  );
};
