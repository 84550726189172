import React from 'react';

import IconText from '@airnz/ui/IconText';
import { createBem } from '@airnz/ui/bem';
import { H3 } from '@airnz/ui/Heading';

import { Aircraft } from '../../models/AircraftData';
import FillingAircraft from '../FillingAircraft';

const bem = createBem('fuel-SelectedAircraftType');

interface SelectedAircraftTypeProps {
  selectedAircraft: Aircraft;
}

const SelectedAircraftType = ({
  selectedAircraft,
}: SelectedAircraftTypeProps) => (
  <H3>
    <IconText className={bem()} icon={<FillingAircraft />}>
      {selectedAircraft.aircraftName}
    </IconText>
  </H3>
);

export default SelectedAircraftType;
