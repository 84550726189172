import React from 'react';

import { combineClassNames, createBem } from '@airnz/ui/bem';
import Button from '@airnz/ui/Button';

import history from '../../utils/history';

import './LinkButton.scss';

interface LinkButtonProps {
  to: string;
  children?: React.ReactNode;
  primary?: boolean;
  linkData?: any;
  className?: string;
  size?: 'sm' | 'md';
  icon?: React.ReactElement;
  onClick?(): void;
}

const bem = createBem('fuel-LinkButton');

const LinkButton = ({
  to,
  children,
  primary,
  className,
  linkData,
  size,
  icon,
  onClick,
}: LinkButtonProps) => (
  <Button
    className={combineClassNames(bem(), className)}
    primary={primary}
    size={size}
    icon={icon}
    onClick={() => {
      history.push(to, linkData);
      if (onClick) {
        onClick();
      }
    }}
  >
    {children}
  </Button>
);

export default LinkButton;
