export default {
  header: {
    id: 'fuel.NotFound.header',
    defaultMessage: 'Sorry, that page doesn’t exist',
  },
  description: {
    id: 'fuel.NotFound.description',
    defaultMessage: 'Let’s go back to the homepage.',
  },
  button: {
    id: 'fuel.NotFound.button',
    defaultMessage: 'OK',
  },
};
