import React, { useContext } from 'react';

import Header from '@airnz/ui/Header';
import { createBem } from '@airnz/ui/bem';

import { rootPath } from '../../utils/routeConstants';
import UserContext from '../../state/UserContext';
import ProfileMenu from '../ProfileMenu';
import PageHeaderLinks from '../PageHeaderLinks/PageHeaderLinks';
import { Role } from '../../models/User';

import './PageHeader.scss';

const bem = createBem('fuel-PageHeader');

export interface PageHeaderProps {
  signOut(): void;
}

const PageHeader = ({ signOut }: PageHeaderProps) => {
  const userState = useContext(UserContext);
  return (
    <Header
      className={bem()}
      navItems={
        userState.role !== Role.unknown &&
        userState.role !== Role.none && <PageHeaderLinks user={userState} />
      }
      actionItems={
        userState &&
        userState.isAuthenticated && (
          <ProfileMenu user={userState} signOut={signOut} />
        )
      }
      logoTo={rootPath}
    />
  );
};

export default PageHeader;
