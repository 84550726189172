import {
  IPublicClientApplication,
  InteractionStatus,
} from '@azure/msal-browser';
import * as Sentry from '@sentry/react';

import { ExtendedAccountInfo, getAccessToken } from '../msal';

type HttpMethod = 'POST' | 'GET' | 'PATCH' | 'PUT';

export interface ApiClientFactoryOptions {
  baseUrl: string;
  instance: IPublicClientApplication;
  account: ExtendedAccountInfo | null;
  inProgress: InteractionStatus;
}

export interface ApiClient {
  request<Payload, Response>(
    url: string,
    method: HttpMethod,
    options: Payload
  ): Promise<Response>;
}

export const apiClientFactory = ({
  baseUrl,
  instance,
  account,
  inProgress,
}: ApiClientFactoryOptions): ApiClient => ({
  request: async <Payload, Response>(
    url: string,
    method: HttpMethod,
    payload: Payload
  ): Promise<Response> => {
    const fullUrl = `${baseUrl}${url}`;
    const accessTokenResponse = await getAccessToken(
      instance,
      account,
      inProgress
    );
    if (
      accessTokenResponse instanceof Object &&
      accessTokenResponse.status === 'success'
    ) {
      const headers = {
        Authorization: `Bearer ${accessTokenResponse.accessToken}`,
        'Content-Type': 'application/json',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
        'access-control-allow-origin': '*',
      };
      const body = payload ? JSON.stringify(payload, null, 2) : undefined;

      const options = {
        headers,
        method,
        body,
      };

      const response = await fetch(fullUrl, options);

      if (Number(response.status >= 500)) {
        throw new Error(response.statusText);
      }

      const text = await response.text();

      if (
        !response.ok ||
        Number(response.status) >= 300 ||
        Number(response.status) < 200
      ) {
        const messageCodes = JSON.parse(text)?.messageCodes;
        const errorResponse = {
          type: 'Error',
          message: response.statusText || 'Something went wrong',
          code: response.status,
          messageCodes,
        };
        throw errorResponse;
      }
      return text ? JSON.parse(text) : {};
    } else {
      Sentry.captureMessage(`response is: ${accessTokenResponse} `);
      throw new Error('Problem fetching token');
    }
  },
});
