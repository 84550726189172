import React, { useContext, useMemo } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import {
  FuelDocketApiClient,
  fuelDocketApiClientFactory,
} from '../../utils/api/fuelDocketApiClient';

export interface ApiBucket {
  fuelDocketApiClient: FuelDocketApiClient;
}

export const createDummyApiClient = <T extends object>() =>
  new Proxy<T>({} as any, { get: () => Promise.resolve(undefined) });

export const emptyApiBucket: ApiBucket = {
  fuelDocketApiClient: createDummyApiClient<FuelDocketApiClient>(),
};

export const ApiBucketContext = React.createContext<ApiBucket>(emptyApiBucket);

export interface ApiProviderProps {
  apiBucket: ApiBucket;
  children: React.ReactNode;
}

// Provides access to ApiClients.
// To be utilized in demos, tests and as a foundation
// for more sophisticated ApiProviders.
export const GenericApiProvider = ({
  children,
  apiBucket,
}: ApiProviderProps) => (
  <ApiBucketContext.Provider value={apiBucket}>
    {children}
  </ApiBucketContext.Provider>
);

export interface ConnectedApiProviderProps {
  children: React.ReactNode;
}

// Provides ApiClients connected to Azure and ready to use
export const ConnectedApiProvider = ({
  children,
}: ConnectedApiProviderProps) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0]);

  const apiBucket: ApiBucket = useMemo(
    () => ({
      fuelDocketApiClient: fuelDocketApiClientFactory(
        instance,
        account,
        inProgress
      ),
    }),
    [instance, account, inProgress]
  );

  return (
    <GenericApiProvider apiBucket={apiBucket}>{children}</GenericApiProvider>
  );
};

export const useApiBucket = () => useContext(ApiBucketContext);
