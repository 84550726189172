import React from 'react';

import Footer from '@airnz/ui/Footer';
import FooterColumn from '@airnz/ui/FooterColumn';
import FooterLink from '@airnz/ui/FooterLink';
import { Message } from '@airnz/ui/intl';
import { combineClassNames, createBem } from '@airnz/ui/bem';

import messages from './PageFooter.messages';
import createGtmEvent from '../../utils/createGtmEvent';

const bem = createBem('fuel-PageFooter');

interface PageFooterProps {
  className?: string;
}
const PageFooter = ({ className }: PageFooterProps) => (
  <Footer className={combineClassNames(bem(), className)}>
    <FooterColumn>
      <FooterLink
        to="https://www.airnewzealand.co.nz/privacy"
        onClick={() => createGtmEvent('Footer', 'Click link', 'Privacy')}
        openInNew
      >
        <Message {...messages.privacy} />
      </FooterLink>
      <FooterLink
        to="https://www.airnewzealand.co.nz/legal-terms-and-conditions"
        onClick={() =>
          createGtmEvent('Footer', 'Click link', 'Terms and conditions')
        }
        openInNew
      >
        <Message {...messages.terms} />
      </FooterLink>
    </FooterColumn>
  </Footer>
);

export default PageFooter;
