export const newDocketPath = '/new-docket';
export const singleDocketPath = '/dockets/:id';
export const editDocketPath = '/edit-docket/:id';
export const docketsPath = '/dockets';
export const rootPath = '/';
export const noAccessPath = '/no-access';
export const notFoundPath = '/not-found';

export type StaticPathType =
  | '/new-docket'
  | '/dockets/:id'
  | '/edit-docket/:id'
  | '/dockets'
  | '/'
  | '/no-access'
  | '/not-found';

// dynamic path generators
const createPathGenerator = (base: string) => (id: string | number) =>
  `/${base}/${id}`;

export const createDocketPath = createPathGenerator('dockets');
export const createEditDocketPath = createPathGenerator('edit-docket');
