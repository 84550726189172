import React from 'react';

import { createBem } from '@airnz/ui/bem';
import AircraftIcon from './AircraftIcon';

import './FillingAircraft.scss';

const bem = createBem('fuel-FillingAircraft');

const FillingAircraft = () => (
  <div className={bem()}>
    <div className={bem('background')} />
    <AircraftIcon className={bem('icon')} size={22} />
  </div>
);

export default FillingAircraft;
