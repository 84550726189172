

import React from 'react';
import { MsalAuthenticationResult } from '@azure/msal-react';

import Alert from '@airnz/ui/Alert';
import PageBody from '@airnz/ui/PageBody';
import PageBlock from '@airnz/ui/PageBlock';
import Header from '@airnz/ui/Header';
import Footer from '@airnz/ui/Footer';

import { buildAuthErrorMessage } from '../../utils/msal';

const AuthenticationError = (authErrorResult: MsalAuthenticationResult) => (
  <>
    <Header />
    <PageBody>
      <PageBlock>
        <Alert type="danger">{buildAuthErrorMessage(authErrorResult)}</Alert>
      </PageBlock>
    </PageBody>
    <Footer />
  </>
);

export default AuthenticationError;
