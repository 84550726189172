interface Data {
  event: string;
  eventDetails: {
    category: string;
    action: string;
    label: string;
  };
}

const GA_LATENCY_ALLOWANCE = 200;

// For use when an event results in a page refresh (allows reasonable time for the GA event to send)
export const allowForGoogleAnalyticsSendingLatency = (callback: () => void) => {
  setTimeout(callback, GA_LATENCY_ALLOWANCE);
};

export default (category: string, action: string, label?: string) => {
  const data: Data = {
    event: 'trackEventNoEcommerce',
    eventDetails: {
      category,
      action,
      label: label || '',
    },
  };
  window.dataLayer?.push(data);
};
