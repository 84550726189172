import {
  InteractionRequiredAuthError,
  InteractionStatus,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { useMsal, useAccount } from '@azure/msal-react';
import * as Sentry from '@sentry/react';

import { appConfig } from '../config/env';
import tokenClaimsToUserTransform from '../tokenClaimsToUserTransform';
import { ExtendedAccountInfo } from './msal.types';

const useAccessToken = () => {
  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts[0]);

  const acquireAccessToken = async () =>
    getAccessToken(instance, account, inProgress);

  if (acquireAccessToken instanceof Object) {
    return {
      acquireAccessToken,
    };
  }
  return;
};

const getAccessToken = async (
  instance: IPublicClientApplication,
  account: ExtendedAccountInfo | null,
  inProgress: InteractionStatus
) => {
  const { scope } = appConfig;
  try {
    if (!account) {
      throw new Error(
        'An authenticated account is required to retrieve an access token.'
      );
    }

    if (inProgress !== InteractionStatus.None) {
      throw new Error('A msal interaction is currently in progress.');
    }

    const res = await instance.acquireTokenSilent({
      scopes: [scope],
      account,
    });
    if (res && res.account) {
      return {
        status: 'success',
        accessToken: res.accessToken,
        user: tokenClaimsToUserTransform(res.account),
      };
    }
    throw new Error('Failed to retrieve an access token.');
  } catch (error) {
    // https://docs.sentry.io/platforms/node/guides/azure-functions/
    // Try to log all the token errors
    Sentry.captureException(error);
    await Sentry.flush(2000);

    if (error instanceof InteractionRequiredAuthError) {
      return instance.acquireTokenRedirect({
        scopes: [scope],
      });
    }
    return { status: 'failed' };
  }
};

export { useAccessToken, getAccessToken };
