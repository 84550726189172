/* eslint-disable */
const dataLayer = dataLayer || [];
(function (w, d, s, l, i) {
  const dl = l !== 'dataLayer' ? '&l=' + l : '',
    f = d.getElementsByTagName(s)[0],
    j = d.createElement(s);
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-KKRVGZK');
