import React, { useState, useContext } from 'react';

import Button from '@airnz/ui/Button';
import PageBlock from '@airnz/ui/PageBlock';
import PageHeading from '@airnz/ui/PageHeading';
import { createBem } from '@airnz/ui/bem';
import Alert from '@airnz/ui/Alert';
import ModalDialog from '@airnz/ui/ModalDialog';

import DocketForm from '../../components/DocketForm';
import LinkButton from '../../components/LinkButton';
import DocketData from '../../models/DocketData';
import {
  initialDocketFormState,
  initialModalState,
} from '../../state/initialState';
import { createDocketPath } from '../../utils/routeConstants';
import ModalData from '../../models/ModalData';
import DocketsEditAndCreateErrorMessage from '../../components/DocketsEditAndCreateErrorMessage';
import createGtmEvent, {
  allowForGoogleAnalyticsSendingLatency,
} from '../../utils/createGtmEvent';
import UIConfigContext from '../../state/UIConfigContext';
import UserContext from '../../state/UserContext';
import { useFuelDocketApiClient } from '../../utils/api/useFuelDocketApiClient';

const bem = createBem('fuel-NewDocket');

const NewDocket = () => {
  const [modalData, setModalData] = useState<ModalData>(initialModalState);
  const [open, toggleOpen] = useState(false);
  const uiConfig = useContext(UIConfigContext);
  const user = useContext(UserContext);
  const client = useFuelDocketApiClient();

  // close modal and clear form fields
  // TODO: The Form component in airnz-ui could be updated to support resetting form fields instead.
  const reload = () => {
    location.reload();
  };

  const submitHandler = (formData: DocketData) => {
    const data = {
      docketNumber: formData.docketNumber,
      totalFuelRequested: formData.totalFuelRequested,
      totalFuelRequestedUnitOfMeasure: formData.totalFuelRequestedUnitOfMeasure,
      preTransactionFuel: formData.preTransactionFuel,
      preTransactionFuelUnitOfMeasure: formData.preTransactionFuelUnitOfMeasure,
      aircraftRegistration: formData.aircraftRegistration,
      volumeTransferred: formData.volumeTransferred,
      volumeTransferredUnitOfMeasure: formData.volumeTransferredUnitOfMeasure,
      density: formData.density,
      densityUnitOfMeasure: formData.densityUnitOfMeasure,
      fuelDocketType: formData.fuelDocketType,
      flightNumber:
        formData.flightNumber && formData.flightNumber.toUpperCase() === 'NZ'
          ? ''
          : formData.flightNumber,
      airportCode: formData.airportCode,
      fuelProvider: formData.fuelProvider,
    };
    const request = { data };

    return client
      .createDocket(request)
      .then(response => {
        toggleOpen(true);
        const onClose = reload;
        setModalData({
          heading: (
            <div className={bem('dialogHeader', 'success')}>Thank you</div>
          ),
          body: (
            <Alert
              minimal
              type="success"
              className={bem('dialogBody', 'success')}
            >
              Docket <b>{data.docketNumber}</b> was successfully created.
            </Alert>
          ),
          actions: (
            <>
              <Button
                onClick={() => {
                  onClose();
                  createGtmEvent(
                    'Create docket',
                    'Click modal button',
                    'Create another docket'
                  );
                }}
                primary
                className={bem('primaryModalButton')}
              >
                Create another docket
              </Button>
              <LinkButton
                to={createDocketPath(response.fuelDocketId)}
                className={bem('secondaryModalButton')}
                onClick={() =>
                  createGtmEvent(
                    'Create docket',
                    'Click modal button',
                    'View docket'
                  )
                }
              >
                View docket
              </LinkButton>
            </>
          ),
          onClose,
        });

        createGtmEvent(
          'Create docket',
          'Successfully created docket',
          `Role: ${user.role}, port: ${response.airportCode}, fuelProvider: ${response.fuelProvider}`
        );
      })
      .catch(error => {
        const closeModal = () => toggleOpen(false);
        toggleOpen(true);
        setModalData({
          heading: (
            <div className={bem('dialogHeader', 'error')}>
              Something went wrong
            </div>
          ),
          body: (
            <DocketsEditAndCreateErrorMessage
              docketNumber={data.docketNumber}
              errorDetails={error}
              mode="create"
              user={user}
            />
          ),
          actions: (
            <Button
              onClick={() => {
                closeModal();
                createGtmEvent('Create docket', 'Close modal');
              }}
              primary
              className={bem('primaryModalButton')}
            >
              Close
            </Button>
          ),
          onClose: closeModal,
        });

        const requestData = error && JSON.parse(error.config);
        createGtmEvent(
          'Create docket',
          'Error creating docket',
          `Role: ${user.role}, port: ${requestData.airportCode}, fuelProvider: ${requestData.fuelProvider} `
        );
      });
  };

  return (
    <PageBlock className={bem()}>
      <PageHeading>Create a fuel docket</PageHeading>
      <DocketForm
        submitHandler={submitHandler}
        initialDocketState={{
          ...initialDocketFormState,
          createdBy: user.name,
          airportCode:
            user.airportCodes?.length === 1 ? user.airportCodes[0] : '',
          fuelProvider:
            user.organisations?.length === 1 ? user.organisations[0] : '',
        }}
        user={user}
        getAircraftDetailsConfig={client.getAircraftDetailsConfig}
        uiConfig={uiConfig}
      />
      <ModalDialog
        onClose={() => {
          if (modalData.onClose) {
            createGtmEvent('Create docket', 'Close modal');
            allowForGoogleAnalyticsSendingLatency(modalData.onClose);
          }
        }}
        heading={modalData.heading}
        actions={modalData.actions}
        open={open}
      >
        {modalData.body}
      </ModalDialog>
    </PageBlock>
  );
};

export default NewDocket;
