import { DensityRange } from '../../models/AircraftData';
import UnitOfMeasure, {
  MassUnitOfMeasure,
  VolumeUnitOfMeasure,
} from '../../models/UnitOfMeasure';
import getUnit from '../../utils/getUnit';

const setDefaultUomMessage = (uom?: UnitOfMeasure) =>
  `In ${uom?.toLowerCase()}`;

export default {
  fuellerLabel: {
    id: 'fuel.DocketForm.fuellerLabel',
    defaultMessage: 'Created by',
  },
  portLabel: {
    id: 'fuel.DocketForm.portLabel',
    defaultMessage: 'Port',
  },
  fuelProviderLabel: {
    id: 'fuel.DocketForm.fuelProviderLabel',
    defaultMessage: 'Fuel provider',
  },
  docketNumberLabel: {
    id: 'fuel.DocketForm.docketNumberLabel',
    defaultMessage: 'Docket number',
  },
  aircraftRegistrationLabel: {
    id: 'fuel.DocketForm.aircraftRegistrationLabel',
    defaultMessage: 'Aircraft registration',
  },
  flightNumberLabel: {
    id: 'fuel.DocketForm.flightNumberLabel',
    defaultMessage: 'Flight number',
  },
  fuelRequestedLabel: {
    id: 'fuel.DocketForm.fuelRequestedLabel',
    defaultMessage: 'Total fuel requested',
  },
  fuelRequestedHint: (uom?: MassUnitOfMeasure) => ({
    id: 'fuel.DocketForm.fuelRequestedHint',
    defaultMessage: setDefaultUomMessage(uom),
  }),
  preTransactionFuelLabel: {
    id: 'fuel.DocketForm.preTransactionFuelLabel',
    defaultMessage: 'Pre-transaction fuel',
  },
  preTransactionFuelHint: (uom?: MassUnitOfMeasure) => ({
    id: 'fuel.DocketForm.preTransactionFuelHint',
    defaultMessage: setDefaultUomMessage(uom),
  }),
  volumeTransferredLabel: {
    id: 'fuel.DocketForm.volumeTransferredLabel',
    defaultMessage: 'Volume delivered',
  },
  volumeTransferredHint: (uom?: VolumeUnitOfMeasure) => ({
    id: 'fuel.DocketForm.volumeTransferredHint',
    defaultMessage: setDefaultUomMessage(uom),
  }),
  densityLabel: {
    id: 'fuel.DocketForm.densityLabel',
    defaultMessage: 'Density',
  },
  densityHint: (densityRange?: DensityRange) => ({
    id: 'fuel.DocketForm.densityHint',
    defaultMessage: `Between ${densityRange && densityRange.lowRange} and
      ${densityRange && densityRange.highRange} ${
      densityRange && getUnit(densityRange.unitOfMeasure)
    }`,
  }),
  submitButton: {
    id: 'fuel.DocketForm.submitButton',
    defaultMessage: 'Submit docket',
  },
  resubmitButton: {
    id: 'fuel.DocketForm.resubmitButton',
    defaultMessage: 'Save & resubmit',
  },
  cancelButton: {
    id: 'fuel.DocketForm.cancelButton',
    defaultMessage: 'Cancel',
  },
};
