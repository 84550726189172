import format from 'date-fns/format';
import subMonths from 'date-fns/sub_months';

const formatDate = (date: Date | number): string => {
  const formattedDate = format(date, 'YYYY-MM-DDTHH:mm:ss');
  if (formattedDate === 'Invalid Date') {
    throw new Error(
      `Date format: "${date.toString()}" (type ${typeof date}) is invalid`
    );
  }

  return formattedDate;
};

export const docketsGenesis = '2018-11-01';

export const getMonthAgoDateString = (): string => {
  const monthAgo = subMonths(new Date(), 1);
  return format(monthAgo, 'YYYY-MM-DD');
};

export const getTodayDateString = (): string =>
  format(new Date(), 'YYYY-MM-DD');

export const minutesFromNowToUtc = (minutesFromNow: number) =>
  new Date(Date.now() + 1000 * 60 * minutesFromNow).toUTCString();

export const longTime = new Date(Date.now() + 999999999999).toUTCString();

export default formatDate;
