import React from 'react';
import ReactDOM from 'react-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './utils/msal';
import App from './App';
import * as Sentry from '@sentry/react';
import { appConfig as env } from './utils/config/env';

Sentry.init({
  dsn: env.sentryDsn,
  enabled: env.sentryEnabled === 'true',
  environment: env.sentryEnvironment,
});

const msalInstance = new PublicClientApplication(msalConfig);

export const main = () => {
  const rootEl = document.getElementById('root');
  ReactDOM.render(<App msalInstance={msalInstance} />, rootEl);

  // Hot Module Replacement
  if (module.hot) {
    module.hot.accept('./App', () => {
      import('./App').then((app: any) => {
        const NextApp = app.default;
        ReactDOM.render(<NextApp />, rootEl);
      });
    });
  }
};

main();
