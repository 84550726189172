import UnitOfMeasure from '../models/UnitOfMeasure';

const unitsMap = {
  KILOGRAMS: 'kg',
  LITERS: 'L',
  'KILOGRAMS PER LITER': 'kg/L',
  'KILOGRAMS PER GALLON': 'kg/USG',
  GALLONS: 'USG',
  TONS: 't',
};

const getUnit = (unit: UnitOfMeasure) => unitsMap[unit];

export default getUnit;
