import React, { useState, useRef } from 'react';

import { createBem } from '@airnz/ui/bem';
import Button from '@airnz/ui/Button';
import Download from '@airnz/ui/icons/Download';

import DocketData from '../../models/DocketData';

import './CsvDownloadButton.scss';

interface CsvDownloadButtonProps {
  docketData: DocketData[];
  onClick?(): void;
}

const bem = createBem('fuel-CsvDownloadButton');

const CsvDownloadButton = ({ docketData, onClick }: CsvDownloadButtonProps) => {
  const [url, setUrl] = useState<string>('');
  const [timeouts, setTimeouts] = useState<any[]>([]);
  const anchorElement = useRef<HTMLAnchorElement>(null);

  const createBlob = (): Blob => {
    const fieldNames = {
      docketNumber: 'Docket number',
      createdBy: 'Created by',
      fuelProvider: 'Fuel provider',
      createdDateTimeLocal: 'Created date',
      airportCode: 'Airport code',
      aircraftRegistration: 'Tail',
      flightNumber: 'Flight number',
      totalFuelRequested: 'Total fuel requested',
      totalFuelRequestedUnitOfMeasure: 'Total fuel requested unit',
      preTransactionFuel: 'Pre-transaction fuel',
      preTransactionFuelUnitOfMeasure: 'Pre-transaction fuel unit',
      volumeTransferred: 'Volume delivered',
      volumeTransferredUnitOfMeasure: 'Volume delivered unit',
      density: 'Density',
      densityUnitOfMeasure: 'Density unit',
    };
    const formatCsv = collectionToCSV(fieldNames);
    const csv = formatCsv(docketData);
    const blob = new Blob([csv], { type: 'text/csv' });
    return blob;
  };

  const collectionToCSV = (fields: any) => (collection: DocketData[]) => {
    const keys = Object.keys(fields);
    const headers = keys.map(key => `"${fields[key]}"`).join(',');
    const extractKeyValues = (record: any) =>
      keys.map(key => `"${record[key]}"`).join(',');
    return collection.reduce(
      (csv, record) => `${csv}\n${extractKeyValues(record)}`.trim(),
      headers
    );
  };

  /**
   * Set the blob url as the href on the anchor element
   */
  const handleClick = () => {
    const blob = createBlob();
    setUrl(URL.createObjectURL(blob));
    checkAndDownload();
    if (onClick) {
      onClick();
    }
  };

  /**
   * Check whether the anchor elements exists in the dom, and if so, programmatically click on the element to download the file
   */
  const checkAndDownload = () => {
    if (anchorElement && anchorElement.current) {
      anchorElement.current.click();
      cleanUp();
    } else {
      const timeoutId = setTimeout(checkAndDownload, 100);
      const timeoutsArray = [...timeouts, timeoutId];
      setTimeouts(timeoutsArray);
      return;
    }
  };

  /**
   * Revoke url and clear any timeouts
   */
  const cleanUp = () => {
    URL.revokeObjectURL(url);
    for (const timeout of timeouts) {
      clearTimeout(timeout);
    }
    setUrl('');
  };

  return (
    <>
      <Button
        onClick={handleClick}
        icon={<Download />}
        className={bem('button')}
      >
        Download
      </Button>
      {url ? (
        <a
          href={url}
          download="dockets.csv"
          ref={anchorElement}
          className={bem('anchor')}
        >
          Download
        </a>
      ) : null}
    </>
  );
};

export default CsvDownloadButton;
