import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import PageHeading from '@airnz/ui/PageHeading';
import PageBlock from '@airnz/ui/PageBlock';
import PageActions from '@airnz/ui/PageActions';
import Button from '@airnz/ui/Button';
import LoadingOverlay from '@airnz/ui/LoadingOverlay';
import { createBem } from '@airnz/ui/bem';
import Edit from '@airnz/ui/icons/Edit';
import Print from '@airnz/ui/icons/Print';

import DocketDetails from '../../components/DocketDetails';
import LinkButton from '../../components/LinkButton';
import DocketData from '../../models/DocketData';
import { createEditDocketPath } from '../../utils/routeConstants';
import printPage from '../../utils/printPage';
import UserContext from '../../state/UserContext';
import ViewDocketErrorMessage from '../../components/ViewDocketErrorMessage';
import createGtmEvent from '../../utils/createGtmEvent';
import { Role } from '../../models/User';
import { useFuelDocketApiClient } from '../../utils/api/useFuelDocketApiClient';

import './Docket.scss';

interface TParams {
  id: string;
}

const bem = createBem('fuel-Docket');

interface DocketProps extends RouteComponentProps<TParams, {}, DocketData> {}

const Docket = ({ match, location }: DocketProps) => {
  const [docketData, setDocketData] = useState<DocketData | null>(
    location.state ? location.state : null
  );
  const [error, setError] = useState<number | undefined>(undefined);
  const user = useContext(UserContext);
  const client = useFuelDocketApiClient();

  useEffect(() => {
    if (!docketData) {
      client
        .fetchDocket(match.params.id)
        .then(response => {
          if (response) {
            setDocketData(response);
          }
        })
        .catch(err => {
          if (err && err.code) {
            setError(err.code);
          }
        });
    }
  }, [match.params.id, docketData, client]);

  const isOwnDocket = docketData?.createdBy === user.name;

  const canEditDocket =
    user &&
    docketData &&
    (user.role === 'AOC' ||
      user.role === 'support' ||
      (user.role === 'fueller' && isOwnDocket) ||
      (user.role === 'engineer' && isOwnDocket));

  return (
    <PageBlock>
      <div className={bem()}>
        <PageHeading>
          {docketData && !error
            ? `Docket ${docketData.docketNumber}`
            : 'Docket'}
        </PageHeading>
        <LoadingOverlay open={!docketData && !error} />
        {error && !docketData ? (
          <ViewDocketErrorMessage
            docketId={match.params.id}
            errorCode={error}
          />
        ) : null}
        {docketData && !error ? (
          <>
            <DocketDetails data={docketData} />
            <PageActions className={bem('pageActions')}>
              {canEditDocket ? (
                <LinkButton
                  className={bem('button', 'edit')}
                  to={createEditDocketPath(docketData.fuelDocketId)}
                  linkData={docketData}
                  icon={<Edit />}
                  primary
                  onClick={() =>
                    createGtmEvent(
                      'Single docket',
                      'Click Edit & resubmit',
                      docketData.fuelDocketId
                    )
                  }
                >
                  Edit & resubmit
                </LinkButton>
              ) : null}
              {user.role !== Role.fueller && (
                <Button
                  onClick={() => {
                    printPage();
                    createGtmEvent(
                      'Single docket',
                      'Print',
                      docketData.fuelDocketId
                    );
                  }}
                  icon={<Print />}
                >
                  Print
                </Button>
              )}
            </PageActions>
          </>
        ) : null}
      </div>
    </PageBlock>
  );
};

export default Docket;
