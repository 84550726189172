import React, { useEffect, useState, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { useFuelDocketApiClient } from '../../utils/api/useFuelDocketApiClient';
import {
  initialUIConfigState,
  UIConfiguration,
} from '../../models/UIConfigData';
import UIConfigContext from '../../state/UIConfigContext';
import UserContext from '../../state/UserContext';
import { Role } from '../../models/User';

interface UiConfigContextProviderProps {
  children: React.ReactNode;
}

const UiConfigContextProvider = ({
  children,
}: UiConfigContextProviderProps) => {
  const [uiConfig, setUiConfig] = useState<UIConfiguration>(
    initialUIConfigState
  );
  const [configFetched, setConfigFetched] = useState<boolean>(false);
  const apiClient = useFuelDocketApiClient();
  const userState = useContext(UserContext);
  const { inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.Logout) {
      return;
    }

    const isUnauthorized =
      userState.role === Role.unknown || userState.role === Role.none;
    if (!configFetched && !isUnauthorized) {
      apiClient.getUiConfig().then(response => {
        setUiConfig(response);
        setConfigFetched(true);
      });
    }
    return;
  }, [uiConfig, apiClient, configFetched, userState, inProgress]);

  return (
    <UIConfigContext.Provider value={uiConfig}>
      {children}
    </UIConfigContext.Provider>
  );
};

export default UiConfigContextProvider;
