import React, { useContext, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';

import {
  newDocketPath,
  docketsPath,
  singleDocketPath,
  noAccessPath,
  editDocketPath,
  rootPath,
  notFoundPath,
} from '../../utils/routeConstants';
import Docket from '../Docket';
import NewDocket from '../NewDocket';
import DocketsPage from '../DocketsPage';
import EditDocket from '../EditDocket';
import UserContext from '../../state/UserContext';
import { Organisation, Role } from '../../models/User';
import NoAccess from '../NoAccess';
import NotFound from '../NotFound';
import DocketData from '../../models/DocketData';

const Routes = () => {
  const user = useContext(UserContext);
  const [docketData, setDocketData] = useState<DocketData[] | null>(null);

  return (
    <AuthenticatedTemplate>
      <Switch>
        {/* The initial page shown on login is the NewDocket page for fuellers, and the DocketsPage (dashboard) for everyone else. */}
        <Route
          exact
          path={rootPath}
          render={() => (
            <>
              {user.role === Role.none && <NoAccess />}
              {user.role !== Role.none &&
                user.organisations === Organisation.AirNZ &&
                user.role !== Role.fueller && (
                  <DocketsPage
                    docketData={docketData}
                    setDocketData={setDocketData}
                  />
                )}
              {user.role === Role.fueller && <NewDocket />}
            </>
          )}
        />
        <Switch>
          <Route exact path={newDocketPath}>
            <NewDocket />
          </Route>
          <Route exact path={docketsPath}>
            <DocketsPage
              docketData={docketData}
              setDocketData={setDocketData}
            />
          </Route>
          <Route path={singleDocketPath} component={Docket} />
          <Route path={editDocketPath} component={EditDocket} />
          <Route exact path={noAccessPath}>
            <NoAccess />
          </Route>
          <Route exact path={notFoundPath}>
            <NotFound />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Switch>
    </AuthenticatedTemplate>
  );
};

export default Routes;
