import React from 'react';
import DatePickerFormField from '@airnz/ui/forms/DatePickerFormField';
import { Range } from '@airnz/ui/Calendar';
import endOfToday from 'date-fns/end_of_today';
import addMonths from 'date-fns/add_months';
import dateFormat from 'date-fns/format';
import { docketsGenesis } from '../../utils/formatDate';

const SearchDatePicker = () => {
  const today = dateFormat(endOfToday(), 'YYYY-MM-DD');
  const nextMonth = dateFormat(addMonths(today, 1), 'YYYY-MM-DD');
  const calendarRange: Range<string> = {
    from: dateFormat(docketsGenesis, 'YYYY-MM-DD'),
    to: nextMonth,
  };

  return (
    <DatePickerFormField
      id="dateRange"
      today={today}
      name="dateRange"
      label="Date range"
      range={calendarRange}
      selectRange
      fromLabel="Start date"
      toLabel="End date"
    />
  );
};

export default SearchDatePicker;
