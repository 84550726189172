export interface EnvConfig {
  authorityBase: string;
  authorityExtension: string;
  clientId: string;
  redirectUri: string;
  policyName: string;
  scope: string;
  sentryEnabled: string;
  sentryDsn: string;
  sentryEnvironment: string;
}

export interface AppWindow {
  appConfig?: EnvConfig;
}

export const appConfig: EnvConfig = ((window as unknown) as AppWindow)
  ?.appConfig || {
  authorityBase: '',
  authorityExtension: '',
  clientId: '',
  redirectUri: '',
  policyName: '',
  scope: '',
  sentryEnabled: '',
  sentryDsn: '',
  sentryEnvironment: '',
};
